import React, { useState } from 'react';
import { ActionButton } from './global/buttons/ActionButton';
import { useLocation } from 'react-router';
interface ProtocolCardProps {
    // Add any necessary props here, e.g., contract instances, user address
    protocolId: string;
    logoImg: string;
    name: string;
    category: string;
    handleProtocolSelect: (protocol: string) => void;
}

const ProtocolCard: React.FC<ProtocolCardProps> = (props) => {
    const location = useLocation();

    const isSelected = location.pathname.includes(props.protocolId)

    return (
        <div className="h-full">
            <div className="h-full">
                <div className="flex flex-col justify-between space-y-4 bg-[#4E4E4E] rounded-2xl p-2 h-full">
                    <div className="flex">
                        <img src={props.logoImg} className="h-8" alt={`${props.name} icon`} />
                        <div className="ml-2">
                            <h2 className="md:text-3xl text-xl font-bold">{props.name}</h2>
                            <hr />
                            <p className="text-sm">{props.category}</p>
                        </div>
                    </div>
                    <div className="flex justify-end items-center">
                        <ActionButton
                            style="primary"
                            classes={`${isSelected && "underline"} px-4 text-black font-semibold hover:text-white`}
                            onClick={() => props.handleProtocolSelect(props.protocolId)}
                        >
                            {props.protocolId === "lido" ? "Preview" : "Select"}
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProtocolCard;
import { useEffect, useState } from "react";
import { FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { ConfigVariables } from "../config";

const useAllAaveReserves = (userAccount?: string, refreshKey?: number) => {
    const [aaveReserves, setAaveReserves] = useState<any[] | undefined>();
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
        const fetchAaveSummary = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams({
                currentAccount: userAccount || '',
            })
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/aave/reserves?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoading(false);

            const reserves = data;
            setAaveReserves(reserves);
        }
      
        fetchAaveSummary();
    }, [userAccount, refreshKey]);
  
    return { aaveReserves, loading };
  };
  
  export default useAllAaveReserves;
import { UniswapPositionData } from '@BoolDigital/sizzle-types';
import { handlePosition } from "../../../../helpers/uniswapHelpers";
import { useOutletContext, useParams } from "react-router-dom";
import useAllPositions from "../../../../hooks/useAllPositions";
import { useWalletContext } from "../../../../hooks/useWalletContext";
import { useEffect, useState } from "react";
import { ActionButton } from '../../../global/buttons/ActionButton';
import { SizzleAnimated } from "../../../global/SizzleAnimated";

const Claim = () => {
  const { walletAddress } = useWalletContext();
  const { position } = useOutletContext<{ position: UniswapPositionData }>();
  const processedPosition = handlePosition(position);
  const [loading, setLoading] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);

  const handleAction = async (action: string) => {
    if (action === "approve") {
      // do something
      setLoading(true);
      console.log("...approving");
      setTimeout(() => {
        setApproved(true);
        setLoading(false);
      }, 3000)
    } else if (approved === true && action === "collect") {
      // do something
      console.log("...collecting");
    }
  };

  return (
    <section className="text-center font-bold mx-auto md:w-[13vw] mt-4 flex flex-col gap-4 justify-center items-center">
      {position ? (
        <>
          <h1 className="text-lg font-bold">COLLECTING FEES</h1>
          {loading ? (
            <div className="flex justify-center items-center min-h-[12vh]">
              <SizzleAnimated size="sm" />
            </div>
          ) : (
            <>
              <div className="flex gap-6 justify-between items-center my-2">
                <img src={processedPosition?.url0} className="w-[32px] h-[32px]" alt="Token 0" />
                <p>{parseFloat(processedPosition.uncollectedFees0 ?? '').toFixed(4)} {processedPosition.token0Symbol}</p>
              </div>
              <div className="flex gap-6 justify-between items-center my-2">
                <img src={processedPosition?.url1} className="w-[32px] h-[32px]" alt="Token 1" />
                <p>{parseFloat(processedPosition.uncollectedFees1 ?? '').toFixed(4)} {processedPosition.token1Symbol}</p>
              </div>
            </>
          )}
          {approved ? (
            <ActionButton style="primary" classes="px-8 font-bold lg:h-[2em]" onClick={() => handleAction("collect")}>{loading ? "Collecting Fees" : "Collect Fees"}</ActionButton>
          ) : (
            <ActionButton style="primary" classes="px-8 font-bold lg:h-[2em]" onClick={() => handleAction("approve")}>{loading ? "Approving" : "Approve"}</ActionButton>
          )}
        </>
      ) : (
        <div className="flex justify-center items-center">
          <SizzleAnimated size="sm" />
        </div>
      )}
    </section>
  );
};

export default Claim;

import { ethers, BigNumber } from 'ethers';
import liquidityABI from '../../contracts/contracts/LiquidityUni.sol/LiquidityUni.json';
import IWeth from '../../contracts/contracts/SwapUni.sol/WETH.json';
import { ContractAddresses } from '../../config';
import { UniswapPathStep } from '@BoolDigital/sizzle-types';


// const result = await liquidityUni.connect(owner).mintNewPosition(
    // {
    //     token0: token0Address,
    //     token1: token1Address,
    //     fee: fee,
    //     tickLower: tickLower,
    //     tickUpper: tickUpper,
    //     recipient: owner,
    //     deadline: Math.floor(Date.now() / 1000) + 60 * 20,
    //   },
    //   token0Amount,
    //   token1Amount
    // );
interface MintInputs {
    token0: string,
    token1: string,
    fee: 100 | 500 | 3000 | 10000,
    tickLower: number,
    tickUpper: number,
    recipient: ethers.Signer,
    deadline: number
}

export const mintPosition = async (token0Amount: BigNumber, token1Amount: BigNumber, mintInputs: MintInputs) => {
    const liquidityContract = new ethers.Contract(ethers.utils.getAddress(ContractAddresses.ethereum.mainnet.LiquidityUni), liquidityABI.abi, mintInputs.recipient);

    const tx = await liquidityContract.mintNewPosition(mintInputs, token0Amount, token1Amount);
    await tx.wait();
    console.log("Position Minted");
}
import { useEffect, useState } from 'react';
import { ConfigVariables } from '../config';
import { useDebounceEffect } from './useDebounceEffect';
import { UniswapPathStep } from '@BoolDigital/sizzle-types';

const useUniswapQuote = (
    _address: string | undefined,
    _tokenIn: string | undefined,
    _tokenOut: string | undefined,
    _amount: number | undefined,
    _type: 'in' | 'out') => {
    const [exactAmount, setExactAmount] = useState<string | undefined>();
    const [pricePer, setPricePer] = useState<number | undefined>();
    const [path, setPath] = useState<UniswapPathStep[] | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchQuote = async () => {
            if (!_address || !_tokenIn || !_tokenOut || (!_amount && _amount !== 0) || !_type) {
                return;
            }

            const queryParams = new URLSearchParams({
                userAddress: _address,
                tokenInAddress: _tokenIn,
                tokenOutAddress: _tokenOut,
                amount: _amount.toString(),
                type: _type,
            })
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/uniswap/get-quote?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            const amount = data?.exactAmount;
            const pricePer = data?.pricePer;
            const path = data?.path;
            setExactAmount(amount);
            setPricePer(pricePer);
            setPath(path);
        }

        setLoading(true);
        fetchQuote().finally(() => setLoading(false)).catch((e) => setLoading(false));
    }, [_address, _tokenIn, _tokenOut, _amount, _type]);

    return { exactAmount, pricePer, loading, path };
};

export default useUniswapQuote;
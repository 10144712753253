import { Box, Button, Center, Flex, Input, InputGroup, InputRightAddon, Text, useDisclosure, Image, Img } from "@chakra-ui/react";
import { useEffect, useState, useContext } from "react";
import { ethers } from "ethers";
import { useWalletContext } from "../../../../hooks/useWalletContext";
import { ActionButton } from "../../../global/buttons/ActionButton";
import { useNavigate, Outlet, useParams } from "react-router-dom";
import useAllPositions from "../../../../hooks/useAllPositions";
import { FaInfinity } from "react-icons/fa";
import { SizzleAnimated } from "../../../global/SizzleAnimated";
import CloseButton from "../../../global/buttons/CloseButton";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { UniswapContext } from "../UniswapProvider";

const ManageInterface = () => {
  const navigate = useNavigate();
  const { positionId } = useParams();
  const { refreshKey } = useContext(UniswapContext);
  const { walletAddress, readOnly } = useWalletContext();
  const { uniswapPositions, loadingUniswap } = useAllPositions(walletAddress, refreshKey);
  const [position, setPosition] = useState<any | undefined>();

  useEffect(() => {
    if (uniswapPositions) {
      const uniswapPosition = uniswapPositions?.find(position => position.id.toString() === positionId?.toString())
      console.log(`Uniswap Position: ${JSON.stringify(uniswapPosition)}`);
      if (uniswapPosition) {
        const url0: string = uniswapPosition.token0Img ?? `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(uniswapPosition.token0Address)}/logo.png?raw=true`
        const url1: string = uniswapPosition.token1Img ?? `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(uniswapPosition.token1Address)}/logo.png?raw=true`
        const depositedUSD: number = (parseFloat(uniswapPosition.amount0 ?? '0') * (uniswapPosition.token0PriceUSD ?? 0)) + (parseFloat(uniswapPosition.amount1 ?? '0') * (uniswapPosition.token1PriceUSD ?? 0))
        const uncollectedFeesUSD: number = (parseFloat(uniswapPosition.uncollectedFees0 ?? '0') * (uniswapPosition.token0PriceUSD ?? 0)) + (parseFloat(uniswapPosition.uncollectedFees1 ?? '0') * (uniswapPosition.token1PriceUSD ?? 0))
        let minPrice: number;
        let maxPrice: number | null;
        if (uniswapPosition.tickLower <= -887270) {
          minPrice = 0;
        } else {
          minPrice = Math.pow(1.0001, uniswapPosition.tickLower);
        }

        if (uniswapPosition.tickUpper >= 887270) {
          maxPrice = null;
        } else {
          maxPrice = Math.pow(1.0001, uniswapPosition.tickUpper);
        }

        setPosition(uniswapPosition); // Use setState to update the position state
        setPosition((prevPosition: any) => ({
          ...prevPosition,
          url0,
          url1,
          depositedUSD,
          uncollectedFeesUSD,
          minPrice,
          maxPrice
        }));
      }
    }
  }, [positionId, uniswapPositions]);

  return (
    <article className="relative border border-[#A3A3A3] rounded-2xl p-4 my-4 font-font-primary">
      <CloseButton path="/uniswap" />
      {position ? (
        <div className="p-2">
          <span className="flex justify-between">
            <Flex gap={2} align={'center'}>
              <Flex>
                <Box w={'32px'} h={'32px'}>
                  <Image src={position?.url0} />
                </Box>
                <Box w={'32px'} h={'32px'} ml={'-16px'}>
                  <Image src={position?.url1} />
                </Box>
              </Flex>
              <span>
                <p className="text-xl font-medium">{position.token0Symbol} / {position.token1Symbol}</p>
                <sup className="text-[#ffffff7a] text-lg">{position.feeTier / 10000}%</sup>
              </span>
            </Flex>
            <p className="text-lg font-medium">ID: {position.id}</p>

          </span>
          <ul className="md:flex md:justify-between mt-4">
            <li className="py-4 flex flex-col items-center lg:inline-block md:py-0">
              <span className="flex gap-1 items-center">
                <h3 className="text-md font-semibold">Liquidity</h3>
                <ActionButton style="primary-xs" classes="px-[0.2rem] font-medium" onClick={() => navigate("add")}>Add</ActionButton>
                <ActionButton style="ghost-border" classes="text-[#73B0BE] px-[0.2rem] font-medium" onClick={() => navigate("remove")}>Remove</ActionButton>
              </span>
              <p className="font-medium">${position?.depositedUSD.toFixed(2)}</p>
              <p className="text-[#ffffff7a]">{parseFloat(position?.amount0 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {position?.token0Symbol}</p>
              <p className="text-[#ffffff7a]">{parseFloat(position?.amount1 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {position?.token1Symbol}</p>
            </li>
            <li className="py-4 flex flex-col items-center lg:inline-block md:py-0">
              <span className="flex gap-1 items-center">
                <h3 className="text-md font-semibold">Unclaimed Fees</h3>
                <ActionButton style="primary-xs" classes="px-[0.2rem] font-medium" onClick={() => navigate("claim")}>Claim</ActionButton>
              </span>
              <p className="font-medium">${position?.uncollectedFeesUSD.toFixed(2)}</p>
              <p className="text-[#ffffff7a]">{parseFloat(position?.uncollectedFees0 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {position?.token0Symbol}</p>
              <p className="text-[#ffffff7a]">{parseFloat(position?.uncollectedFees1 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {position?.token1Symbol}</p>
            </li>
            <li className="py-4 flex flex-col items-center lg:inline-block lg:text-right md:py-0">
              <h3 className="text-md font-semibold">Price Range</h3>
              <p className="font-medium">{position?.token0Symbol} per {position?.token1Symbol}</p>
              <p className="text-[#ffffff7a]">Min: <span className="text-white">{position?.minPrice.toFixed(5)}</span></p>
              <p className="text-[#ffffff7a] flex gap-2 items-center lg:float-right">Max: <span className="text-white">{position?.maxPrice ? position?.maxPrice.toFixed(5) : <FaInfinity />}</span></p>
            </li>
          </ul>
        </div>
      ) :
        <div>
          <SizzleAnimated size="sm" />
        </div>
      }
      <Outlet context={{ position: position }} />
    </article>
  );
}

export default ManageInterface;
import { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import erc20Abi from '@openzeppelin/contracts/build/contracts/ERC20.json'

const baseAbi = ['function allowance(address fromUser, address toUser) view returns (uint256)'];

const useTokenAllowance = (
    owner: string | undefined,  
    spender: string | undefined, 
    tokenAddress: string | undefined, 
    provider: ethers.providers.Provider | undefined,
    amount: any,
    method: string = 'allowance',
    abi: string[] = baseAbi,
    updated: boolean = false,
    ) => {
  const [allowance, setAllowance] = useState<BigNumber | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => { 
    const fetchAllowance = async () => {
      if (!owner || !spender || !tokenAddress || !provider) {
        setAllowance(undefined);
        return;
      }

      setLoading(true);
      const tokenContract = new Contract(tokenAddress, abi, provider);
      try {
        const rawAllowance = await tokenContract[method](owner, spender);
        setAllowance(rawAllowance);
      } catch (error) {
        console.error('Error fetching token allowance:', error);
        setAllowance(undefined);
      }
      setLoading(false);
    };

    fetchAllowance();
  }, [owner, spender, tokenAddress, amount, method, abi, updated]);

  return { allowance, loading };
};

export default useTokenAllowance;
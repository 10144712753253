import { useNavigate } from "react-router-dom";

interface CloseButtonProps {
  path: string;
  onClick?: () => void;
}

const CloseButton = ({ path }: CloseButtonProps) => {
  const navigate = useNavigate();
  return (
    <button className="absolute top-1 right-1" onClick={() => navigate(path)} aria-label="Close"> {/* @TODO ADD CLOSE FUNCTION */}
      <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer stroke-white hover:stroke-[#AAAAAA]" width="22" height="22" viewBox="0 0 24 24" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </button>
  )
}

export default CloseButton;
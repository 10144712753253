import { useState, useRef, useEffect } from 'react';

export function useBoundingRect<T extends HTMLElement>(): [
  React.RefObject<T>,
  DOMRect | null
] {
  const ref = useRef<T>(null);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    const updateRect = () => {
      if (ref.current) {
        setBoundingRect(ref.current.getBoundingClientRect());
      }
    };

    updateRect();
    window.addEventListener('resize', updateRect);
    return () => {
      window.removeEventListener('resize', updateRect);
    };
  }, []);

  return [ref, boundingRect];
}
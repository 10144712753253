import React, { useState } from 'react';
import { Carousel } from './carousel/Carousel';
import aaveLogo from '../assets/images/protocol-logos/aave-aave-logo.png';
import uniswapLogo from '../assets/images/protocol-logos/uniswap-uni-logo.png';
import lidoLogo from '../assets/images/protocol-logos/lido-lido-logo.png';
import ProtocolCard from './ProtocolCard';
import { useNavigate } from 'react-router';

interface DAppSelectionBannerProps {
  // Add any necessary props here, e.g., contract instances, user address
  handleProtocolSelect: (protocol: string) => void;
}

const DAppSelectionBanner: React.FC<DAppSelectionBannerProps> = (props) => {
  const navigate = useNavigate();

  return (
    <div className="border border-[#A3A3A3] rounded-2xl p-2">
      <Carousel gap={2}>
        <div className="h-full flex-grow">
          <ProtocolCard protocolId='aave' logoImg={aaveLogo} name='Aave' category='Lending and borrowing' handleProtocolSelect={() => {
            navigate('aave')
          }} />
        </div>
        <div className="h-full flex-grow">
          <ProtocolCard protocolId='uniswap' logoImg={uniswapLogo} name='Uniswap' category='Swaps and liquidity' handleProtocolSelect={() => {
            navigate('uniswap')
          }} />
        </div>
        <div className="h-full flex-grow">
          <ProtocolCard protocolId='lido' logoImg={lidoLogo} name='Lido' category='...coming soon' handleProtocolSelect={() => {
            navigate('lido')
          }} />
        </div>
      </Carousel>
    </div>
  );
};

export default DAppSelectionBanner;
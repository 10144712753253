import aave from '../../contracts/contracts/BasicAave.sol/BasicAave.json'; 
import { BigNumber, ethers } from "ethers";
import { ContractAddresses } from "../../config";

export const repay = async (asset: string, amount: BigNumber, mode: 1 | 2, signer: ethers.Signer) => {
    const basicAave = new ethers.Contract(ContractAddresses.ethereum.mainnet.BasicAave, aave.abi, signer);
    const address = await signer.getAddress();
    const tx = await basicAave.repay(
        asset,
        amount,
        mode,
        address
      );
    await tx.wait();
}
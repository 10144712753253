import AaveMain from "./AaveMain"
import { AaveProvider } from "./AaveProvider"

const AaveWrapper = () => {
    return (
        <AaveProvider>
            <AaveMain />
        </AaveProvider>
    )
}

export default AaveWrapper;
import { useEffect, useState } from "react";
import { FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { ConfigVariables } from "../config";

const useAaveUserReserves = (userAccount?: string, refreshKey?: number) => {
    const [aaveReserves, setAaveReserves] = useState<FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse> | undefined>();
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
        const fetchAaveSummary = async () => {
            if(!userAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: userAccount || '',
            })
            setLoading(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/aave-user-summary?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoading(false);

            const userSummary = data?.aaveUserSummary;
            setAaveReserves(userSummary);
        }
      
        fetchAaveSummary();
    }, [userAccount, refreshKey]);
  
    return { aaveReserves, loading };
  };
  
  export default useAaveUserReserves;
import { debounce } from "lodash";
import { useEffect, useState } from "react";

export function useDebouncedValue(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debouncedSetter = debounce((newValue) => {
      setDebouncedValue(newValue);
    }, delay);

    debouncedSetter(value);

    return () => {
      debouncedSetter.cancel();
    };
  }, [value, delay]);

  return debouncedValue;
}
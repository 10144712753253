const LidoStatistics = () => {

  return (
    <section>
      <h2 className="text-3xl font-bold py-2">Lido Statistics</h2>
      <div className="md:flex items-center w-full justify-between text-center md:text-left">
        <span>
          <span className="text-xl font-light underline underline-offset-4">Annual Percentage Rate</span>
          <p className="text-lg font-light">3.8%</p>
        </span>
        <span>
          <span className="text-xl font-light underline underline-offset-4">Total staked with Lido</span>
          <p className="text-lg font-light">8,815,265.325 ETH</p>
        </span>
        <span>
          <span className="text-xl font-light underline underline-offset-4">Stakers</span>
          <p className="text-lg font-light">282758</p>
        </span>
        <span>
          <span className="text-xl font-light underline underline-offset-4">Token Market Cap</span>
          <p className="text-lg font-light">$15,826,528,033</p>
        </span>
      </div>
    </section>
  )
}

export default LidoStatistics;
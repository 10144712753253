import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UniswapToken, UniswapTopToken } from "@BoolDigital/sizzle-types";
import { Navigate, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useWalletContext } from "../../../hooks/useWalletContext";

import { Flex, Input, InputGroup, InputLeftAddon, InputRightAddon, useDisclosure } from "@chakra-ui/react";
import { AiOutlineCaretDown, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import PiSwapLight from "react-icons/ai"
import SelectTokenModal from "./SelectTokenModal";
import useUniswapQuote from "../../../hooks/useUniswapQuote";
import { ActionButton } from "../../global/buttons/ActionButton";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import CloseButton from "../../global/buttons/CloseButton";
import { useParams } from "react-router";
import { approveToken, getTokenContractDetails } from "../../../helpers/erc20Helpers";
import defaultTokenIcon from '../../../assets/icons/global/defaultTokenIcon.svg';

import useUniswapTopTokens from "../../../hooks/useUniswapTopTokens";
import useUniswapPairData from "../../../hooks/uniswap/useUniswapPairData";
import { BigNumber, ethers } from "ethers";
import { tickToPrice } from "@uniswap/v3-sdk";
import { Price, Token } from "@uniswap/sdk-core";
import { useNetwork, useProvider, useSigner } from "wagmi";
import { alignTick, getMintAmounts, priceToTick } from "../../../helpers/uniswapHelpers";
import JSBI from "jsbi";
import debounce from "lodash.debounce";
import { useDebouncedValue } from "../../../hooks/uniswap/useDebounceChangeHandler";
import { updateUrlParams } from "../../../helpers/general";
import { useTokenBalance } from "../../../hooks/general/useTokenBalance";
import useTokenAllowance from "../../../hooks/useTokenAllowance";
import { ContractAddresses } from "../../../config";
import { mintPosition } from "../../../services/uniswap/liquidity";
import { UniswapContext } from "./UniswapProvider";

const CreateInterface = () => {
  const { walletAddress, readOnly } = useWalletContext();
  const { refresh } = useContext(UniswapContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { chain } = useNetwork();
  const provider = useProvider();
  const { data: signer } = useSigner();
  const [token0, setToken0] = useState<UniswapToken | undefined>();
  const [token1, setToken1] = useState<UniswapToken | undefined>();
  const [amount0, setAmount0] = useState<number>(0.0);
  const [amount1, setAmount1] = useState<number>(0.0);
  const [activeInput, setActiveInput] = useState<'amount0' | 'amount1'>('amount0');
  const [fee, setFee] = useState<100 | 500 | 3000 | 10000 | undefined>(undefined);
  const [prices, setPrices] = useState<{ min: { price: Price<Token, Token> | undefined, tick: number }, max: { price: Price<Token, Token> | undefined, tick: number } }>({ min: { price: undefined, tick: -10 }, max: { price: undefined, tick: 10 } });
  const [minPriceInput, setMinPriceInput] = useState<string>('');
  const [maxPriceInput, setMaxPriceInput] = useState<string>('');
  const debouncedMinPriceInput = useDebouncedValue(minPriceInput, 1000);
  const debouncedMaxPriceInput = useDebouncedValue(maxPriceInput, 1000);
  const [fullRangeToggle, setFullRangeToggle] = useState<boolean>(false);
  const { isOpen: token0Open, onOpen: onToken0Open, onClose: onToken0Close } = useDisclosure();
  const { isOpen: token1Open, onOpen: onToken1Open, onClose: onToken1Close } = useDisclosure();
  const [approvals, setApprovals] = useState<{ token0: boolean, token1: boolean }>({ token0: false, token1: false });
  const { tokens, loading: loadingTopTokens } = useUniswapTopTokens();
  const { poolData, loading: poolDataLoading } = useUniswapPairData(token0?.address, token1?.address, token0?.decimals, token1?.decimals);
  const [approve0Loading, setApprove0Loading] = useState<boolean>(false)
  const [approve1Loading, setApprove1Loading] = useState<boolean>(false)
  const [mintLoading, setMintLoading] = useState<boolean>(false);
  const { balance: balance0 } = useTokenBalance({ provider: provider as any, walletAddress: walletAddress, tokenAddress: token0?.address, reload: approve0Loading }) // set button reload for approval
  const { balance: balance1 } = useTokenBalance({ provider: provider as any, walletAddress: walletAddress, tokenAddress: token1?.address, reload: approve1Loading }) // set button reload for approval
  const { allowance: allowance0 } = useTokenAllowance(
    walletAddress,
    ContractAddresses.ethereum.mainnet.LiquidityUni,
    token0?.address,
    provider,
    amount0,
    undefined,
    undefined,
    approve0Loading // TODO: set for button reload to update
  );
  const { allowance: allowance1 } = useTokenAllowance(
    walletAddress,
    ContractAddresses.ethereum.mainnet.LiquidityUni,
    token1?.address,
    provider,
    amount0,
    undefined,
    undefined,
    approve1Loading // TODO: set for button reload to update
  );

  const totalLiquidity = !poolData ? ethers.BigNumber.from(0) : Object.values(poolData).reduce((total, { liquidity }) => {
    return total.add(liquidity ?? ethers.BigNumber.from(0));
  }, ethers.BigNumber.from(0));

  const [loading, setLoading] = useState<boolean>(false);

  const fetchToken = async (tokenAddress: string) => {
    if (tokens?.length) {
      setLoading(true);
      let tokenSelected: UniswapTopToken | undefined = tokens?.find(
        (token) => token?.id === tokenAddress
      );
      const { decimals }: { decimals: number } = await getTokenContractDetails(tokenAddress);
      if (tokenSelected) {
        let token: UniswapToken = {
          address: tokenSelected.id,
          decimals: decimals,
          img_url: tokenSelected?.details?.image_small || defaultTokenIcon,
          symbol: tokenSelected.symbol,
        };

        setLoading(false);
        return token;
      }
      setLoading(false);

    }
  };

  const validateFeeTier = (value: string | undefined) => {
    let fee: 100 | 500 | 3000 | 10000 | undefined;
    switch (value) {
      case '100':
        fee = 100;
        break;
      case '500':
        fee = 500;
        break;
      case '3000':
        fee = 3000;
        break;
      case '10000':
        fee = 10000;
        break;
      default:
        fee = undefined; // Or handle the default case as needed
    }
    return fee
  }

  // set selected tokens
  useEffect(() => {
    setLoading(true)
    if (location.search === '') {
      setToken0(undefined);
      setToken1(undefined);
    }
    const urlParams = new URLSearchParams(location.search);
    const token0FromURL: string | null = urlParams.get("token0");
    const token1FromURL: string | null = urlParams.get("token1");
    const fetchTokens = async () => {
      if (token0FromURL) {
        const token0 = await fetchToken(token0FromURL);
        setToken0(token0);
      }
      if (token1FromURL) {
        const token1 = await fetchToken(token1FromURL);
        setToken1(token1);
      }
      setLoading(false)
    };

    if (!loadingTopTokens && !!tokens?.length) {
      fetchTokens();
    }
    // if (feeTier && ['100', '500', '3000', '10000'].includes(feeTier)) {
    //   setFee(feeTier);
    // }
  }, [location.search, tokens, loadingTopTokens]);

  // check for approvals for tokens
  useEffect(() => {
    setApprovals({ token0: false, token1: false });
  }, [amount0, amount1]);

  // set selected fee tier
  useEffect(() => {
    setFee(undefined);
    if (poolData) {
      type AccumulatorType = { feeTier: string | undefined; liquidity: ethers.BigNumber };
      const highestLiquidityTier = Object.entries(poolData).reduce<AccumulatorType>(
        (max, [feeTier, data]) => {
          if (!data.liquidity) {
            return max;
          }
          if (max.liquidity.lt(data.liquidity)) {
            return { feeTier, liquidity: ethers.BigNumber.from(data.liquidity) };
          }
          return max;
        },
        { feeTier: undefined, liquidity: ethers.BigNumber.from(0) } // Initial object with liquidity and feeTier
      );
      const params = new URLSearchParams(location.search);
      const fee = validateFeeTier(highestLiquidityTier.feeTier);

      if (fee) {
        updateUrlParams(navigate, location.pathname, params);
        setFee(fee)
      }
    }
  }, [poolData])

  useEffect(() => {
    if (!poolData || !fee || !token0 || !token1 || !token0.decimals || !token1.decimals) return

    const feeTierData = poolData[fee];
    // set initial min/max tick
    const baseToken = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
    const quoteToken = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);
    const defaultMinTick = (feeTierData.tick - (5 * feeTierData.tickSpacing))
    const defaultMaxTick = (feeTierData.tick + (5 * feeTierData.tickSpacing))
    const minPrice = tickToPrice(baseToken, quoteToken, defaultMinTick);
    const maxPrice = tickToPrice(baseToken, quoteToken, defaultMaxTick);
    if (shouldInvertPrice()) {
      setPrices({ min: { price: maxPrice as any, tick: defaultMaxTick }, max: { price: minPrice as any, tick: defaultMinTick } })
      setMinPriceInput(maxPrice.toFixed(6));
      setMaxPriceInput(minPrice.toFixed(6));
    } else {
      setPrices({ min: { price: minPrice as any, tick: defaultMinTick }, max: { price: maxPrice as any, tick: defaultMaxTick } })
      setMinPriceInput(minPrice.toFixed(6));
      setMaxPriceInput(maxPrice.toFixed(6));
    }
  }, [fee, token0, token1])

  const approveButtonFn = async (index: 0 | 1) => {
    if (index === 0) {
      if (!signer || !amount0 || !token0) return

      try {
        setApprove0Loading(true);
        await approveToken(
          token0.address,
          ContractAddresses.ethereum.mainnet.LiquidityUni,
          ethers.utils.parseUnits(`${amount0}`, token0?.decimals),
          signer
        );
      } catch (error) {
        console.error("Transaction was rejected or failed", error);
      } finally {
        setApprove0Loading(false);
      }
    } else {
      if (!signer || !amount1 || !token1) return

      try {
        setApprove1Loading(true);
        await approveToken(
          token1.address,
          ContractAddresses.ethereum.mainnet.LiquidityUni,
          ethers.utils.parseUnits(`${amount1}`, token1?.decimals),
          signer
        );
      } catch (error) {
        console.error("Transaction was rejected or failed", error);
      } finally {
        setApprove1Loading(false);
      }
    }

  }

  const shouldInvertPrice = (): boolean => {
    if (!poolData || !fee) return false;
    const poolTokenOrder = poolData[fee].tokenOrder;
    // Check if the token order in the UI matches the pool's token order
    if (token0?.address === poolTokenOrder[0] && token1?.address === poolTokenOrder[1]) {
      return false; // No need to inverse the price
    }
    return true; // Need to inverse the price
  };

  const displayPrice = (): { price: string, symbols: string } => {
    if (!poolData || !token0 || !token1 || !fee) return { price: '', symbols: '' };
    const feeTierData = poolData[fee];
    if (!feeTierData || !feeTierData.price) return { price: '', symbols: '' };

    const { price } = feeTierData;
    const inversePrice = 1 / price;

    const needInverse = shouldInvertPrice();

    return { price: needInverse ? inversePrice.toFixed(6) : price.toFixed(6), symbols: needInverse ? `${token0?.symbol} per ${token1?.symbol}` : `${token1?.symbol} per ${token0?.symbol}` };
  };

  const flipTokenOrder = () => {
    const newToken0 = token1;
    const newToken1 = token0;
    const params = new URLSearchParams(location.search);
    if (newToken0) {
      params.set('token0', newToken0?.address);
    }
    if (newToken1) {
      params.set('token1', newToken1.address);
    }
    updateUrlParams(navigate, location.pathname, params);
    setToken0(newToken0);
    setToken1(newToken1);
  }

  const handleFullRange = () => {
    if (!poolData || !fee || !poolData[fee].tickSpacing) return

    if (fullRangeToggle) {
      setFullRangeToggle(false);
      return
    }

    setFullRangeToggle(true);
    const feeTierData = poolData[fee];
    let lowestTick = -887272;
    lowestTick = alignTick(lowestTick, feeTierData.tickSpacing)
    let highestTick = 887272;
    highestTick = alignTick(highestTick, feeTierData.tickSpacing);
    if (shouldInvertPrice()) {
      setMinPriceInput('0');
      setMaxPriceInput('Infinity');
      setPrices({
        min: {
          tick: highestTick,
          price: undefined,
        },
        max: {
          tick: lowestTick,
          price: undefined,
        }
      })
    } else {
      setMinPriceInput('0');
      setMaxPriceInput('Infinity');
      setPrices({
        min: {
          tick: lowestTick,
          price: undefined,
        },
        max: {
          tick: highestTick,
          price: undefined,
        }
      })
    }
  }

  const handleMinMaxStep = (type: 'min' | 'max', step: 'increment' | 'decrement') => {
    if (!poolData || !fee || !token0?.decimals || !token1?.decimals) return

    const baseToken = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
    const quoteToken = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);

    const feeTierData = poolData[fee];
    if (type === 'min') {
      const currentTick = prices.min.tick;
      let newTick;
      if ((step === 'increment' && !shouldInvertPrice()) || (step === 'decrement' && shouldInvertPrice())) {
        newTick = currentTick + feeTierData.tickSpacing
        if (newTick > 887272) {
          return
        }
      } else {
        newTick = currentTick - feeTierData.tickSpacing;
        if (newTick < -887272) {
          return
        }
      }
      const newPrice = tickToPrice(baseToken, quoteToken, newTick);
      setPrices({ ...prices, min: { price: newPrice as any, tick: newTick } });
      setMinPriceInput(newPrice.toFixed(6));
    } else {
      const currentTick = prices.max.tick;
      let newTick;
      if ((step === 'increment' && !shouldInvertPrice()) || (step === 'decrement' && shouldInvertPrice())) {
        newTick = currentTick + feeTierData.tickSpacing
        if (newTick > 887272) {
          return
        }
      } else {
        newTick = currentTick - feeTierData.tickSpacing;
        if (newTick < -887272) {
          return
        }
      }
      const newPrice = tickToPrice(baseToken, quoteToken, newTick);
      setPrices({ ...prices, max: { price: newPrice as any, tick: newTick } });
      setMaxPriceInput(newPrice.toFixed(6));
    }
  }

  const handleMinMaxInput = useCallback((value: string, type: 'min' | 'max') => {
    if (!poolData || !fee || !token0?.decimals || !token1?.decimals || isNaN(parseFloat(value))) return

    const baseToken = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
    const quoteToken = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);

    const feeTierData = poolData[fee];
    const priceInverted = shouldInvertPrice();
    const newTick = priceToTick(value, baseToken.decimals, quoteToken.decimals, feeTierData.tickSpacing, priceInverted)

    const tickPrice = tickToPrice(baseToken, quoteToken, newTick);
    if (type === 'min') {
      setPrices({ ...prices, min: { price: tickPrice as any, tick: newTick } })
      setMinPriceInput(tickPrice.toFixed(6));
    } else {
      setPrices({ ...prices, max: { price: tickPrice as any, tick: newTick } })
      setMaxPriceInput(tickPrice.toFixed(6));
    }
  }, [poolData, fee, token0, token1, chain, shouldInvertPrice, setPrices, setMinPriceInput, setMaxPriceInput]);

  useEffect(() => {
    if (
      !isNaN(debouncedMinPriceInput) &&
      ((!prices.min.price && debouncedMinPriceInput.toString() !== '0') ||
        (prices.min.price && debouncedMinPriceInput !== prices.min.price.toFixed(6)))
    ) {
      handleMinMaxInput(debouncedMinPriceInput.toString(), "min");
    }
  }, [debouncedMinPriceInput])

  useEffect(() => {
    if (
      !isNaN(debouncedMaxPriceInput) &&
      ((!prices.max.price && debouncedMaxPriceInput.toString() !== 'Infinity') ||
        (prices.max.price && debouncedMaxPriceInput !== prices.max.price.toFixed(6)))
    ) {
      setFullRangeToggle(false)
      handleMinMaxInput(debouncedMaxPriceInput.toString(), "max");
    }
  }, [debouncedMaxPriceInput])

  useEffect(() => {
    if (activeInput !== 'amount0' || !poolData ||
      !fee ||
      !token0 ||
      !token1 ||
      !token0.decimals ||
      !token1.decimals || isNaN(amount0)) return;

    let tokenA: Token;
    let tokenB: Token;
    const feeTierData = poolData[fee];

    if (shouldInvertPrice()) {
      tokenA = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);
      tokenB = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
    } else {
      tokenA = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
      tokenB = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);
    }

    let tickLower = shouldInvertPrice() ? prices.max.tick : prices.min.tick;
    let tickUpper = shouldInvertPrice() ? prices.min.tick : prices.max.tick;
    tickLower = alignTick(tickLower, feeTierData.tickSpacing);
    tickUpper = alignTick(tickUpper, feeTierData.tickSpacing);
    const _amount1 = getMintAmounts(
      tokenA,
      tokenB,
      fee,
      JSBI.BigInt(feeTierData.sqrtPriceX96.toString()),
      JSBI.BigInt(feeTierData.liquidity.toString()),
      feeTierData.tick,
      tickLower,
      tickUpper,
      JSBI.BigInt(ethers.utils.parseUnits(amount0.toString(), token0.decimals)),
      shouldInvertPrice() ? false : true);
    setAmount1(parseFloat(ethers.utils.formatUnits(BigNumber.from(_amount1.toString()), token1.decimals)));
  }, [amount0, prices])

  useEffect(() => {
    if (activeInput !== 'amount1' || !poolData ||
      !fee ||
      !token0 ||
      !token1 ||
      !token0.decimals ||
      !token1.decimals || isNaN(amount1)) return;

    let tokenA: Token;
    let tokenB: Token;
    const feeTierData = poolData[fee];

    if (shouldInvertPrice()) {
      tokenA = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);
      tokenB = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
    } else {
      tokenA = new Token(chain?.id ?? 1, token0.address, token0.decimals, token0.symbol);
      tokenB = new Token(chain?.id ?? 1, token1.address, token1.decimals, token1.symbol);
    }

    let tickLower = shouldInvertPrice() ? prices.max.tick : prices.min.tick;
    let tickUpper = shouldInvertPrice() ? prices.min.tick : prices.max.tick;
    tickLower = alignTick(tickLower, feeTierData.tickSpacing);
    tickUpper = alignTick(tickUpper, feeTierData.tickSpacing);
    const _amount0 = getMintAmounts(
      tokenA,
      tokenB,
      fee,
      JSBI.BigInt(feeTierData.sqrtPriceX96.toString()),
      JSBI.BigInt(feeTierData.liquidity.toString()),
      feeTierData.tick,
      tickLower,
      tickUpper,
      JSBI.BigInt(ethers.utils.parseUnits(amount1.toString(), token1.decimals)),
      shouldInvertPrice() ? true : false);
    setAmount0(parseFloat(ethers.utils.formatUnits(BigNumber.from(_amount0.toString()), token0.decimals)));
  }, [amount1])

  return (
    <article className="relative border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary">
      <CloseButton path="/uniswap" />
      {poolDataLoading || loading ?
        <div className="mx-auto text-center">
          <SizzleAnimated />
        </div>
        :
        <div className="md:w-1/2 mx-auto lg:w-full lg:flex lg:gap-4 items-center lg:justify-center lg:pt-0 pt-4">
          <div className="py-2">
            <InputGroup className="w-1/2">
              <Input isDisabled={poolDataLoading} type="number" defaultValue="0" value={amount0} onChange={(e) => {
                setActiveInput('amount0');
                setAmount0(parseFloat(e.target.value ?? "0"))
              }} />
              <InputRightAddon onClick={onToken0Open} cursor={'pointer'}>
                <div className="flex gap-2 items-center">
                  {
                    token0?.img_url && <img src={token0?.img_url} className="h-[28px]" />
                  }
                  <p>{token0?.symbol?.toUpperCase() ?? 'SELECT'}</p>
                  <AiOutlineCaretDown />
                </div>
                <SelectTokenModal isOpen={token0Open} onClose={onToken0Close} onTokenSelect={(token) => {
                  const params = new URLSearchParams(location.search);
                  if (token) {
                    params.set('token0', token.address);
                  } else {
                    params.delete('token0')
                  }
                  updateUrlParams(navigate, location.pathname, params)
                  setToken0(token)
                }
                } />
              </InputRightAddon>
            </InputGroup>
            {/* TODO: Update Balance to be balance from chain */}
            <div className="flex gap-2 pt-2 items-center justify-center">
              <p className="text-lg">Balance: {ethers.utils.formatUnits(balance0 ?? 0, token0?.decimals)}</p>
              <ActionButton style="primary-xs" classes="px-2 " onClick={() => setAmount0(parseFloat(ethers.utils.formatUnits(balance0 ?? 0, token0?.decimals)))}>
                Max
              </ActionButton>
            </div>
            <div className="mx-auto text-center h-[2em] mb-2 lg:mb-0">
              {allowance0?.lt(ethers.utils.parseUnits(amount0 ? amount0.toString() : '0', token0?.decimals)) && token0 && <ActionButton
                style={`${approvals.token0 ? "primary-disabled" : "primary"}`}
                classes={`${approvals.token0 ? "border rounded-md" : null} mx-auto my-4 px-8 font-bold h-[2em] font-bold`}
                onClick={() => approveButtonFn(0)}
                isDisabled={!token0 || approvals.token0} // Disable the button when approvals.token0 is true
              >
                {approve0Loading ? <SizzleAnimated size="xs" /> : `Approve ${token0?.symbol.toUpperCase()}`}
              </ActionButton>
              }
            </div>
          </div>
          <div className="py-2 lg:justify-start">
            <InputGroup>
              <Input isDisabled={poolDataLoading} type="number" defaultValue="0" value={amount1} onChange={(e) => {
                setActiveInput('amount1');
                setAmount1(parseFloat(e.target.value ?? "0"))
              }} />
              <InputRightAddon onClick={onToken1Open} cursor={'pointer'}>
                <Flex alignItems={'center'} gap={2}>
                  {
                    token1?.img_url && <img src={token1?.img_url} className="h-[28px]" />
                  }
                  <p>{token1?.symbol?.toUpperCase() ?? 'SELECT'}</p>
                  <AiOutlineCaretDown />
                </Flex>
                <SelectTokenModal isOpen={token1Open} onClose={onToken1Close} onTokenSelect={(token) => {
                  const params = new URLSearchParams(location.search);
                  if (token) {
                    params.set('token1', token.address)
                  } else {
                    params.delete('token1')
                  }
                  updateUrlParams(navigate, location.pathname, params)
                  setToken1(token)
                }} />
              </InputRightAddon>
            </InputGroup>
            {/* TODO: Update Balance to be balance from chain */}
            <div className="flex gap-2 pt-2 items-center justify-center">
              <p className="text-lg">Balance: {ethers.utils.formatUnits(balance1 ?? 0, token1?.decimals)}</p>
              <ActionButton style="primary-xs" classes="px-2" onClick={() => setAmount1(parseFloat(ethers.utils.formatUnits(balance1 ?? 0, token1?.decimals)))}>
                Max
              </ActionButton>
            </div>
            <div className="mx-auto text-center h-[2em] mb-2 lg:mb-0">
              {allowance1?.lt(ethers.utils.parseUnits(amount1 ? amount1.toString() : '0', token1?.decimals)) && token1 && <ActionButton
                style={`${approvals.token1 ? "primary-disabled" : "primary"}`}
                classes={`${approvals.token1 ? "border rounded-md" : null} mx-auto my-4 px-8 font-bold h-[2em] font-bold`}
                onClick={() => approveButtonFn(1)}
                isDisabled={!token1 || approvals.token1} // Disable the button when approvals.token1 is true
              >
                {approve1Loading ? <SizzleAnimated size="xs" /> : `Approve ${token1?.symbol.toUpperCase()}`}
              </ActionButton>
              }
            </div>

          </div>
        </div>
      }
      {

        <>
          <section className="flex text-center gap-2 items-center justify-center pt-8">
            {poolData && Object.entries(poolData).map(([feeTier, data]) => {
              const percentage = data.liquidity && totalLiquidity.gt(0) ? (ethers.BigNumber.from(data.liquidity).mul(100).div(totalLiquidity)).toString() : undefined;
              return (
                <ActionButton
                  key={feeTier}
                  style="ghost"
                  onClick={() => {
                    const params = new URLSearchParams(location.search);
                    updateUrlParams(navigate, location.pathname, params);
                    setFee(validateFeeTier(feeTier))
                  }}
                  classes={`${fee === validateFeeTier(feeTier) ? "bg-[#73B0BE]" : null} border border-[#73B0BE] hover:bg-[#73B0BE] rounded-md px-2 w-[125px] h-auto text-left`}
                >
                  <p className="font-semibold text-md">{parseFloat(feeTier) / 10000}%</p>
                  <p className="text-sm">{percentage ? `${percentage}% Selected` : 'Not Yet Created'}</p>
                </ActionButton>
              );
            })}
          </section>
          {fee && poolData &&
            <span className="flex gap-2 items-center justify-center">
              <p>{`${displayPrice().price} ${displayPrice().symbols}`}</p>
              <button onClick={flipTokenOrder}>
                <svg stroke="#73B0BE" fill="#73B0BE" strokeWidth="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M228,48V152a20,20,0,0,1-20,20H112.92a12,12,0,0,1-17.41,16.49l-20-20a12,12,0,0,1,0-17l20-20A12,12,0,0,1,112.92,148H204V52H100a12,12,0,0,1-24,0V48A20,20,0,0,1,96,28H208A20,20,0,0,1,228,48ZM168,192a12,12,0,0,0-12,12H52V108h91.08a12,12,0,0,0,17.41,16.49l20-20a12,12,0,0,0,0-17l-20-20A12,12,0,0,0,143.08,84H48a20,20,0,0,0-20,20V208a20,20,0,0,0,20,20H160a20,20,0,0,0,20-20v-4A12,12,0,0,0,168,192Z"></path></svg>
              </button>
            </span>
          }
        </>
      }

      <section className="lg:flex grid gap-4 justify-center">
        <div className="lg:w-1/4">
          <h3 className="underline underline-offset-[6px] text-lg text-center py-2">Min Price</h3>
          <InputGroup>
            <InputLeftAddon cursor={fullRangeToggle ? 'default' : 'pointer'} onClick={fullRangeToggle ? undefined : () => handleMinMaxStep('min', 'decrement')}>
              <AiOutlineMinus />
            </InputLeftAddon>
            <Input isDisabled={poolDataLoading} type="text" defaultValue="0" value={minPriceInput} onChange={(event) => {
              const value = event.target.value;
              if (!isNaN(parseFloat(value)) || value === 'Infinity' || value === '') {
                setMinPriceInput(event.target.value);
              }
            }} />
            <InputRightAddon
              onClick={fullRangeToggle ? undefined : () => handleMinMaxStep('min', 'increment')}
              cursor={fullRangeToggle ? 'default' : 'pointer'}>
              <AiOutlinePlus />
            </InputRightAddon>
          </InputGroup>
          {token0 && token1 &&
            <p className="text-center">{token1?.symbol} per {token0?.symbol}</p>
          }
        </div>
        <div className="lg:w-1/4">
          <h3 className="underline underline-offset-[6px] text-lg text-center py-2">Max Price</h3>
          <InputGroup>
            <InputLeftAddon onClick={fullRangeToggle ? undefined : () => handleMinMaxStep('max', 'decrement')} cursor={fullRangeToggle ? 'default' : 'pointer'}>
              <AiOutlineMinus />
            </InputLeftAddon>
            <Input isDisabled={poolDataLoading} type="text" defaultValue="0" value={maxPriceInput} pattern="\d*" onChange={(event) => {
              const value = event.target.value;
              if (!isNaN(parseFloat(value)) || value === 'Infinity' || value === '') {
                setMaxPriceInput(event.target.value);
              }
            }} />
            <InputRightAddon onClick={fullRangeToggle ? undefined : () => handleMinMaxStep('max', 'increment')} cursor={fullRangeToggle ? 'default' : 'pointer'}>
              <AiOutlinePlus />
            </InputRightAddon>
          </InputGroup>
          {token0 && token1 &&
            <p className="text-center">{token1?.symbol} per {token0?.symbol}</p>
          }
        </div>
      </section>
      <ActionButton style={fullRangeToggle ? 'primary' : 'ghost'} classes="flex justify-center mx-auto px-8 my-4 font-bold border border-[#73B0BE] rounded-md" onClick={handleFullRange}>Full Range</ActionButton>
      <ActionButton
        style={allowance0?.lt(ethers.utils.parseUnits(amount0 ? amount0.toString() : '0', token0?.decimals)) || allowance1?.lt(ethers.utils.parseUnits(amount1 ? amount1.toString() : '0', token1?.decimals)) ? "primary-disabled" : "primary"}
        classes="my-6 px-8 font-bold h-[2em] flex items-center justify-center mx-auto"
        onClick={async () => {
          if (!signer || !poolData || !token0 || !token1 || !amount0 || !amount1 || !fee) return

          const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
          const feeTierData = poolData[fee];

          try {
            setMintLoading(true);
            if (shouldInvertPrice()) {
              await mintPosition(
                ethers.utils.parseUnits(amount1.toString(), token1?.decimals),
                ethers.utils.parseUnits(amount0.toString(), token0?.decimals),
                {
                  token0: token1?.address,
                  token1: token0?.address,
                  fee: fee,
                  tickLower: alignTick(prices.max.tick, feeTierData.tickSpacing),
                  tickUpper: alignTick(prices.min.tick, feeTierData.tickSpacing),
                  recipient: signer,
                  deadline: deadline,
                }
              );
            } else {
              await mintPosition(
                ethers.utils.parseUnits(amount0.toString(), token0?.decimals),
                ethers.utils.parseUnits(amount1.toString(), token1?.decimals),
                {
                  token0: token0?.address,
                  token1: token1?.address,
                  fee: fee,
                  tickLower: alignTick(prices.min.tick, feeTierData.tickSpacing),
                  tickUpper: alignTick(prices.max.tick, feeTierData.tickSpacing),
                  recipient: signer,
                  deadline: deadline,
                }
              );
            }
            refresh();
          } catch (e) {
            console.error('Failed to mint new position: ', e);
          } finally {
            setMintLoading(false);
          }
        }}>{mintLoading ? <SizzleAnimated size="xs" /> : allowance0?.lt(ethers.utils.parseUnits(amount0 ? amount0.toString() : '0', token0?.decimals)) || allowance1?.lt(ethers.utils.parseUnits(amount1 ? amount1.toString() : '0', token1?.decimals)) ? `Approve Tokens` : 'Create Position'}</ActionButton>
    </article >
  );
}

export default CreateInterface;
import { Menu, MenuButton, MenuList, MenuItem, useDisclosure, CircularProgress, Icon } from "@chakra-ui/react";
import { IoMdContact } from "react-icons/io";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoDiscord } from "react-icons/io5";
import { RiBankFill, RiQuestionLine } from 'react-icons/ri';
import ConnectWalletButton from "./ConnectWalletButton";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { ethers } from "ethers";
import { useWalletContext } from "../hooks/useWalletContext";
import { FaEye } from "react-icons/fa";
import ConnectWalletModal from "./ConnectWalletModal";
import { useNavigate } from "react-router";

import { NavLink } from "react-router-dom";

// Export these from one path
import defiIcon from '../assets/icons/nav/defi.svg';
import homeIcon from '../assets/icons/nav/home.svg';
import logo from '../assets/images/Sizzle-logo-white.svg';
import greenDot from '../assets/icons/nav/green-dot.svg';
import { trackEvent } from "../helpers/googleAnalyticsHelpers";


const NavBar = () => {
    const { isConnected, address } = useAccount();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: web3ModalOpen } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { setWalletAddress, setReadOnly, walletAddress, readOnly } = useWalletContext();
    const navigate = useNavigate();

    const handleDisconnect = () => {
        if (readOnly) {
            setReadOnly(false);
        }
        setWalletAddress();
        disconnect();
    }

    return (
        <div className="w-full bg-[#464646]" >
            <div className="flex justify-between items-center px-2 pb-1 pt-1">
                <nav className="flex gap-6 items-center">
                    <a href="https://sizzle.fi">
                        <img src={logo} alt="Logo" className="py-2 w-24" />
                    </a>
                    <NavLink
                        style={({ isActive, isPending }) => {
                            return {
                                textDecoration: isActive ? "underline" : "",
                                textDecorationColor: isActive ? "#73B0BE" : "",
                                textUnderlineOffset: "0.25em",
                                // color: isPending ? "red" : "black",
                            };
                        }}
                        to="/" className="flex gap-2 items-center mt-4 text-[#C7C7C7] flex-shrink-0">
                        <img src={homeIcon} alt="Home Icon" width={"14px"} />
                        <span className="text-xl font-primary">Home</span>
                    </NavLink>
                    <a
                        href="https://sizzle.fi/about/"
                        style={{
                            textDecoration: "underline",
                            textDecorationColor: "#C7C7C7",
                            textUnderlineOffset: "0.25em",
                            // Additional styles as needed
                        }}
                        className="flex gap-2 items-center mt-4 text-[#C7C7C7] flex-shrink-0"
                        >
                        <BsFillQuestionSquareFill />
                        <span className="text-xl font-primary">About</span>
                    </a>
                    <a
                        href="https://sizzle.fi/contact-us/"
                        style={{
                            textDecoration: "underline",
                            textDecorationColor: "#C7C7C7",
                            textUnderlineOffset: "0.25em",
                            // Additional styles as needed
                        }}
                        className="flex gap-2 items-center mt-4 text-[#C7C7C7] flex-shrink-0"
                        >
                        <IoMdContact />
                        <span className="text-xl font-primary">Contact Us</span>
                    </a>
                    {/* <NavLink to="/defi" className="flex gap-2 items-center mt-4 text-[#C7C7C7] flex-shrink-0">
                        <img src={defiIcon} alt="DeFi Icon" />
                        <span className="text-xl font-primary">DeFi</span>
                    </NavLink> */}
                </nav>
                <div className="flex gap-4 items-center">
                    <a
                        href="https://twitter.com/SizzleFi"
                        className="text-[#C7C7C7] flex-shrink-0"
                        >
                        <FaSquareXTwitter size={'32px'} />
                    </a>
                    <a
                        href="https://discord.gg/QmJnKQmK"
                        className="text-[#C7C7C7] flex-shrink-0"
                        >
                        <IoLogoDiscord size={'32px'} />
                    </a>
                    {(isConnected && address) || (readOnly && walletAddress) ?
                        <Menu>
                            <MenuButton>
                                <div className="flex items-center border-2 border-[#73B0BE] font-primary md:font-black rounded-full px-2 md:p-1">
                                    {readOnly ?
                                        <Icon as={FaEye} mr={2} /> :
                                        <img src={greenDot} alt="Green Dot" className="mr-2" />
                                    }
                                    {`${(walletAddress)?.substring(0, 5)}...${(walletAddress)?.slice((walletAddress).length - 5)}`}
                                </div>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => onOpen()}>Connect New Wallet</MenuItem>
                                <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
                            </MenuList>
                        </Menu>

                        : <button className="border border-[#73B0BE] md:h-[2em] rounded-2xl px-4 m-3" onClick={() => {
                            onOpen();
                            trackEvent({
                                category: 'connect_wallet',
                                action: 'open_connect_modal',
                                label: 'Top Navbar Connect Button',
                                additionalParams: {
                                    'source': 'navbar'
                                }
                            });
                            }}>
                            {web3ModalOpen || isOpen ?
                                <>
                                    <CircularProgress isIndeterminate size="1rem" color="mint.400" mr={2} />
                                    {"Connecting..."}
                                </>
                                : 'Connect'}
                        </button>
                    }
                </div>
                <ConnectWalletModal isOpen={isOpen} onClose={onClose} />
            </div>
        </div>
    );
}

export default NavBar;
import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import TokenSelect from "../../TokenSelect";
import { UniswapToken } from "@BoolDigital/sizzle-types";
import { useState } from "react";
import { getTokenContractDetails } from "../../../helpers/erc20Helpers";
import { ActionButton } from "../../global/buttons/ActionButton";
import { useNavigate } from "react-router";
interface SelectTokenModalProps {
    isOpen: boolean,
    onClose: () => void,
    onTokenSelect: (data?: UniswapToken) => void,
    header?: string,
    tokenType?: string
}

const SelectTokenModal: React.FC<SelectTokenModalProps> = ({ isOpen, onClose, onTokenSelect, header, tokenType }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState<UniswapToken | undefined>();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (token) {
            setLoading(true);
            const { symbol, decimals } = await getTokenContractDetails(token.address);
            token.decimals = decimals ?? token.decimals;
            token.symbol = symbol ?? token.symbol;

            onTokenSelect(token);

            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set(`token${tokenType}`, token.address);
            const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
            window.history.pushState({ path: newUrl }, '', newUrl);

            setLoading(false);
        }
        onClose();
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <h2 className="text-xl font-bold font-font-primary py-2 px-4">{header ?? 'Select Token'}</h2>
                <ModalBody p={4}>
                    <TokenSelect onTokenSelected={(t) => setToken({ address: t.address, symbol: t.symbol, img_url: t.image_small, decimals: t.decimals })} />
                    <div className="text-center">
                        <ActionButton style={loading ? "primary-disabled" : "primary"} classes="p-2 my-2" onClick={handleSubmit}>Select Token</ActionButton>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default SelectTokenModal;
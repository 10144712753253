import { useWalletContext } from "../../../hooks/useWalletContext";
import { Outlet, useNavigate } from "react-router-dom";
import { ActionButton } from "../../global/buttons/ActionButton";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import LidoStatistics from "./LidoStatistics";
import LidoRewards from "./LidoRewards";

const LidoMain = () => {
  const navigate = useNavigate();
  const { walletAddress, readOnly } = useWalletContext();
  const location = window.location.pathname
  return (
    <div className="pb-4 relative">
      <h1 className="text-3xl font-bold py-2">Lido</h1>
      {/* {!walletAddress ? <p className="border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary mx-auto text-center">Connect wallet to view assets or perform actions</p> : */}
      <div className="flex gap-2">
        <ActionButton style={location === "/lido" ? "primary" : "ghost-border"} onClick={() => navigate("/lido")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Stake</ActionButton>
        <ActionButton style={location === "/lido/wrap" ? "primary" : "ghost-border"} onClick={() => navigate("/lido/wrap")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Wrap</ActionButton>
        <ActionButton style={location === "/lido/withdraw" ? "primary" : "ghost-border"} onClick={() => navigate("/lido/withdraw")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Withdraw</ActionButton>
      </div>
      {/* } */}
      <Outlet />

      <section className="pt-4">
        <LidoStatistics />
        <LidoRewards />
      </section>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#1d232a] bg-opacity-50">
      </div>
    </div>
  )
}

export default LidoMain;
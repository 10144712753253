import { BigNumber, ethers } from "ethers";
import IERC20 from '../contracts/@openzeppelin/contracts/token/ERC20/IERC20.sol/IERC20.json';
import { ConfigVariables } from "../config";

export const approveToken = async (token: string, spender: string, amount: BigNumber, signer: ethers.Signer) => {
    const tokenContract = new ethers.Contract(token, IERC20.abi, signer);
    const tx = await tokenContract.approve(spender, amount);
    await tx.wait();
}

export const getBalance = async (token: string | undefined, user: string | undefined, provider: ethers.providers.Provider) => {
    if (!token || !user || !provider) return;
    
    const tokenContract = new ethers.Contract(token, IERC20.abi, provider);
    const balance: BigNumber = await tokenContract.balanceOf(user);
    return balance;
}

export const getTokenContractDetails = async (token: string): Promise<{ symbol: string, name: string, decimals: number }> => {
    const response = await fetch(`${ConfigVariables.SERVER_URL}/tokens/token/${token}/contract-details`, {
        method: 'GET', 
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    return { symbol: data?.symbol, name: data?.name, decimals: data?.decimals }
}
import { ethers, BigNumber } from 'ethers';
import swapAbi from '../../contracts/contracts/SwapUni.sol/SwapUni.json';
import IWeth from '../../contracts/contracts/SwapUni.sol/WETH.json';
import { ContractAddresses } from '../../config';
import { UniswapPathStep } from '@BoolDigital/sizzle-types';


//     function swap(uint256 _amountIn, PathStep[] calldata _path) public returns (uint256 amountOut) {
export const swapExactIn = async (account: ethers.Signer, amount: BigNumber, route: UniswapPathStep[], isETH: boolean = false) => {
    const swapContract = new ethers.Contract(ethers.utils.getAddress(ContractAddresses.ethereum.mainnet.SwapUni), swapAbi.abi, account);
    let tx;
    if(isETH) {
        tx = await swapContract.swapEth(route, {value: amount});
    } else {
        tx = await swapContract.swap(amount, route);
    }
    await tx.wait();
    console.log("Swap Complete");
}

export const wrapEth = async (amount: BigNumber, account: ethers.Signer) => {
    const WETH = new ethers.Contract(ethers.utils.getAddress(ContractAddresses.ethereum.mainnet.WETH), IWeth.abi, account);
    const tx = await WETH.deposit({ value: amount });
    await tx.wait();
    console.log("ETH Wrapped");
}
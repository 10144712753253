import NavBar from "./NavBar"
import DAppSelectionBanner from "./DAppSelectionBanner"
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Outlet } from "react-router";
import { useNetwork } from "wagmi";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useWalletContext } from "../hooks/useWalletContext";
import { ActionButton } from "./global/buttons/ActionButton";
import { ConfigVariables } from "../config";
import { SizzleAnimated } from "./global/SizzleAnimated";
import { usePageTracking } from "../helpers/googleAnalyticsHelpers";

const MainLayout = () => {
    const { chain } = useNetwork();
    const { walletAddress, readOnly } = useWalletContext();
    const [wrongNetwork, setWrongNetwork] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [faucetLoading, setFaucetLoading] = useState<boolean>(false);

    useEffect(() => {
        if (chain && chain.id !== 1227) {
            setWrongNetwork(true);
        } else {
            setWrongNetwork(false);
        }
    });

    async function switchNetwork() {
        const { ethereum } = window;
        if (ethereum) {
            try {
                await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${(1227).toString(16)}` }],
                });
            } catch (error: any) {
                if (error.code === 4902) {
                    try {
                        await ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                chainId: `0x${(1227).toString(16)}`,
                                rpcUrls: ['https://server.booldigital.com/test-fork'],
                                chainName: "Sizzle Test Fork",
                                nativeCurrency: {
                                                   name: "szETH",
                                                   symbol: "szETH",
                                                   decimals: 18
                                                },
                                },
                            ],
                        } as any);
                    } catch (addError) {
                        console.error(`Error Adding Network: ${addError}`)
                    }
                }
                // Handle other errors
                console.error(`Error switching network: ${error}`)
            }
        }
    }

    async function openFaucet() {
        setFaucetLoading(true);
        try {
            const response = await fetch(`${ConfigVariables.SERVER_URL}/tokens/get-faucet-eth/${walletAddress}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            const data = await response.json();
            if (response.ok) {
                onClose();
            } else {
                console.error(data.message || "Error with faucet");
            }
        } catch (e) {
            console.log(`Error removing token: ${e}`);
        } finally {
            setFaucetLoading(false);
        }
    }
    usePageTracking();

    return (
        <main>
            <div className="w-full bg-red-600 text-white p-4 z-50 flex flex-col lg:flex-row gap-2 items-center justify-center">
                <p>Thank You for testing the new Sizzle Platform.</p>
                <p>For information on how to test<a href="https://booldigital.notion.site/How-To-Test-Sizzle-e2c1b01bc67c485d966f610e16377cc1" target="_blank" className="text-blue-300 ml-1">
                    CLICK HERE
                </a>.</p>
                <p>Need Test Eth?
                    <a onClick={onOpen} style={{ cursor: 'pointer' }} target="_blank" className="text-blue-300 ml-1">
                        CLICK HERE
                    </a>.
                </p>
                <Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent width={'480px'}>
                        <ModalBody width={'480px'}>
                            <p className="pb-1 text-">Send 0.5 Test bETH to <span className="font-bold">{walletAddress}</span> from our faucet?</p>
                            <ActionButton onClick={openFaucet} style='primary' classes="py-1 px-2">{faucetLoading ? <SizzleAnimated size='xs' /> : 'Open Faucet'}</ActionButton>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
            {
                wrongNetwork && (
                    <div className="w-full bg-red-600 text-white p-4 z-50 flex items-center justify-center">
                        <a onClick={switchNetwork} style={{ cursor: 'pointer'}}>Unsupported chain. Please click here to switch to the Sizzle Test Fork.</a>
                    </div>
                )
            }
            <NavBar />
            <CookieConsent
                enableDeclineButton
                buttonStyle={{ backgroundColor: '#62a7b6', color: '#FFFFFF' }}
                declineButtonStyle={{ border: '2px solid #62a7b6', backgroundColor: 'transparent', color: '#62a7b6' }}
                onAccept={() => {
                    Cookies.set("app-user-cookie-consent", "true", { expires: 365 });
                }}
                onDecline={() => {
                    Cookies.set("app-user-cookie-consent", "false", { expires: 365 });
                }}
            >We use cookies to help us provide a better product and experience for you.</CookieConsent>
            <div className="flex flex-col gap-[16px] h-100 w-[80vw] pt-[16px] m-auto">
                <div className="items-center w-full">
                    <DAppSelectionBanner handleProtocolSelect={() => { }} />
                </div>
                <Outlet />
            </div>
        </main>
    );
}

export default MainLayout;
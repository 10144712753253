import { ethers } from "ethers";
import useAllPositions from "../../hooks/useAllPositions";
import { useProvider } from "wagmi";
import AavePositionsHome from "../protocols/aave/AavePositionsHome";
import UniswapPositionsHome from "../protocols/uniswap/UniswapPositionsHome";
import { useWalletContext } from "../../hooks/useWalletContext";

const Positions = () => {
    const { walletAddress, readOnly } = useWalletContext();
    const { aaveUserSummary, loadingAave, uniswapPositions, loadingUniswap } = useAllPositions(walletAddress);
    return (
        <div>
            <h2 className="text-3xl font-bold">Positions</h2>
            {<AavePositionsHome summary={aaveUserSummary} loading={loadingAave} />}
            {<UniswapPositionsHome positions={uniswapPositions} loading={loadingUniswap} />}
        </div>
    );
}

export default Positions;
import React, { useEffect, useState } from "react";
import { ResponsiveLine } from '@nivo/line'
import usePortfolioHistory from "../../hooks/usePortfolioHistory";
import { useWalletContext } from "../../hooks/useWalletContext";
import { Switch } from "../global/Switch";
import { DashboardChartToken, ChartRange, BitqueryToken } from "@BoolDigital/sizzle-types";
import { SizzleAnimated } from "../global/SizzleAnimated";
import useTotalPortfolioValue from "../../hooks/dashchart/useTokenPriceHistory";
import useTokenPriceHistory from "../../hooks/dashchart/useTokenPriceHistory";
import { trackEvent } from "../../helpers/googleAnalyticsHelpers";

interface DashChartProps {
    selectedToken: DashboardChartToken | undefined;
    chartRange: ChartRange;
    setChartRange: (range: ChartRange) => void;
    displayPricelessToken?: boolean;
}

interface EndValue {
    value: number;
    token?: string;
    balance?: boolean;
}

const DashChart = ({ selectedToken, chartRange, setChartRange }: DashChartProps) => {
    const { walletAddress } = useWalletContext();
    let { total, holdings, chartLoading } = usePortfolioHistory(walletAddress, chartRange);
    const [showBalanceHistory, setShowBalanceHistory] = useState<boolean>(false);
    let { tokenData, loading: tokenLoading } = useTokenPriceHistory(selectedToken?.address, chartRange);
    const dataLoading = chartLoading || tokenLoading;

    const [data, setData] = useState<any>();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [startValue, setStartValue] = useState<number>();
    const [endValue, setEndValue] = useState<EndValue>({
        value: 0,
        token: '',
        balance: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        if (selectedToken || refresh) {
            setData(undefined);
        }

        setLoading(true);

        let datapoints: { x: Date, y: number }[] | undefined;
        const selectedHolding: BitqueryToken | undefined = holdings?.find((t) => t.address === selectedToken?.address);
        // set datapoints to selected holding if a token is selected and the selected token is a holding and we are showing balance history
        if (selectedToken && selectedHolding && showBalanceHistory) {
            datapoints = selectedHolding?.values?.map((v) => ({
                x: new Date(v.timestamp),
                y: v.value,
            }));
        } else if (selectedToken && selectedHolding && !showBalanceHistory) {
            datapoints = selectedHolding?.prices?.map((v) => ({
                x: new Date(v.timestamp),
                y: v.value,
            }));
        } else if (walletAddress && total && Object.keys(total)?.length > 0 && !selectedToken) {
            datapoints = Object.entries(total).map(([timestamp, tot]) => ({
                x: new Date(tot.timestamp),
                y: tot.value,
            }));
        } else {
            datapoints = Object.entries(tokenData).map(([timestamp, tot]) => ({
                x: new Date(tot.timestamp),
                y: tot.value,
            }))
        }

        if (datapoints && datapoints.length > 0) {
            datapoints.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
            datapoints = datapoints.filter((p) => new Date(p.x) >= new Date(2021, 0, 1));

            setStartDate(datapoints[0].x);
            setStartValue(datapoints[0].y);
            setEndDate(datapoints[datapoints.length - 1].x);
            setEndValue({ value: datapoints[datapoints.length - 1].y });

            const data = [{
                id: 'value',
                color: '#73B0BE',
                data: datapoints
            }]

            setData(data);

            if (!!selectedToken) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setLoading(false); // Prevent fetching again if selectedToken is present
                return; // Exit the useEffect early
            }

        }
        setLoading(false);

    }, [total, holdings, tokenData, selectedToken, chartRange, refresh, showBalanceHistory]);

    const percentChange = endValue && startValue ? ((endValue.value - startValue) / startValue) * 100 : undefined;
    const dateRanges = ["1D", "1W", "1M", "3M", "6M", "1Y", "ALL"]

    return (
        <div className="w-full h-full p-8 border border-[#A3A3A3] rounded-2xl font-primary">
            <div className="flex md:flex-row flex-col justify-between overflow-hidden">
                <div className="flex items-start gap-2">
                    <div className="flex md:flex-col flex-row">
                        <p className="text-md">{`${selectedToken?.symbol?.toUpperCase() ?? 'Total'} ${!selectedToken || (showBalanceHistory && selectedToken) ? "Balance" : "Price"}`}</p>
                        <div className="flex gap-2 align-middle font-black">
                            <span className="flex items-center">
                                {endValue?.value && !dataLoading ?
                                    <p className="text-2xl font-bold relative">
                                        <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                                        <span className="pl-2">
                                            {endValue?.value?.toFixed(2) || "loading"}
                                        </span>
                                    </p>
                                    :
                                    <></>
                                }
                            </span>
                            {
                                percentChange && !dataLoading ? <p className={`text-xl ${percentChange < 0 ? 'text-red-500' : 'text-green-500'}`}>({percentChange.toFixed(2)}%)</p> : <div />
                            }
                        </div>
                    </div>
                    {
                        selectedToken && holdings?.find((t) => t.address === selectedToken.address) &&
                        <div className="flex gap-2 items-center">
                            <p className="text-sm">Price</p>
                            <Switch checked={showBalanceHistory} changeEvent={(e) => setShowBalanceHistory(e.target.checked)} />
                            <p className="text-sm">Balance</p>
                        </div>
                    }
                </div>
                <div>
                    <div className="flex gap-2">
                        {dateRanges.map(range => {
                            return <button type="button"
                                key={range}
                                onClick={() => {
                                    setChartRange(range as ChartRange);
                                    trackEvent({
                                        category: 'dash-chart',
                                        action: 'change-range',
                                        label: 'Chart Date Range',
                                        additionalParams: {
                                            'range': range
                                        }
                                    });
                                }}
                                className={`${chartRange === range ? 'bg-[#73B0BE]' : 'bg-[#FFFFFF14] hover:bg-[#FFFFFF22]'} text-white text-sm font-bold px-2 rounded`}>
                                {range}
                            </button>
                        })}
                    </div>
                </div>
            </div >
            <div className="w-full h-[90%] pb-8">
                {loading || dataLoading ? (
                    <div className="flex justify-center items-center h-full">
                        <SizzleAnimated />
                    </div>
                ) : (
                    (data && data.length > 0 && !dataLoading) ? (
                        (<ResponsiveLine
                            data={data ?? []}
                            tooltip={({ point }) => {
                                const date = new Date(point.data.x);
                                const formattedDate = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date);
                                let formattedValue = point.data.yFormatted;
                                if (typeof formattedValue === 'number') {
                                    formattedValue = formattedValue.toFixed(6);
                                } else {
                                    formattedValue = parseFloat(formattedValue).toFixed(6);
                                }
                                return (
                                    <div className="bg-white text-black p-2 rounded-2xl">
                                        <p>{formattedDate}: ${formattedValue}</p>
                                    </div>
                                );
                            }}
                            margin={{ top: 15, right: 60, bottom: 40, left: 60 }}
                            xScale={{ type: "time", precision: chartRange === '1D' ? "minute" : "hour" }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                format: (date) => {
                                    const month = date.toLocaleString('default', { month: 'short' });
                                    const day = date.getDate();
                                    const year = date.getFullYear().toString();
                                    return `${month}. ${day}, ${year}`;
                                },
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: 0,
                                tickValues: [startDate, endDate],
                            }}
                            axisLeft={null}
                            lineWidth={3}
                            colors={"#73B0BE"}
                            enableGridX={false}
                            enableGridY={false}
                            pointSize={0}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            curve={'linear'}
                            legends={[]}
                            theme={{
                                axis: {
                                    ticks: {
                                        text: {
                                            fontSize: '0.8rem',
                                            fill: '#73B0BE'
                                        }
                                    }
                                }
                            }}
                        />)
                    ) : (
                        <div className="text-center md:py-12">
                            <p className="text-md">No data available</p>
                            <button onClick={() => setRefresh(true)}> {/* @TODO Add handling for this */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-refresh" width="30" height="30" viewBox="0 0 24 24" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path></svg>
                            </button>
                        </div>
                    )
                )
                }
            </div>
        </div>
    )
}

export default DashChart;
import useCurrentHoldings from "../../hooks/useCurrentHoldings";
import TokenName from "./TokenName";
import { useWalletContext } from "../../hooks/useWalletContext";
import { Dispatch, SetStateAction } from "react";
import { Switch } from "../global/Switch";
import { DashboardChartToken } from "@BoolDigital/sizzle-types";
import { SizzleAnimated } from "../global/SizzleAnimated";

interface HoldingsProps {
    setSelectedToken: (t: DashboardChartToken) => void;
    selectedToken?: DashboardChartToken;
    setDisplayPricelessToken: Dispatch<SetStateAction<boolean>>;
    displayPricelessToken?: boolean;
}

const Holdings: React.FC<HoldingsProps> = ({
    setSelectedToken,
    selectedToken,
    setDisplayPricelessToken,
    displayPricelessToken,
}) => {
    const { walletAddress } = useWalletContext();
    const { holdings, isLoading } = useCurrentHoldings(walletAddress);

    return (
        <div className="mb-4">
            <h2 className="text-3xl font-bold py-2">Holdings</h2>
            <span className="flex gap-2 items-center py-2">
                <p>Display N/A Tokens</p>
                <Switch
                    changeEvent={(e) => {
                        setDisplayPricelessToken(!displayPricelessToken);
                    }}
                />
            </span>
            {isLoading ? (
                <div className="mx-auto py-2">
                    <SizzleAnimated />
                </div>
            ) : (
                <table className="w-full">
                    <thead>
                        <tr className="border-b-2 border-white">
                            <th className="text-left">Token</th>
                            <th className="text-left">Price</th>
                            <th className="text-right">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(holdings?.filter((x) => x.fungible) ?? []).map((t) => {
                            let isSelected: boolean = selectedToken?.address === t.address;
                            return (
                                <tr
                                    key={t.address}
                                    onClick={() =>
                                        t.currentPrice
                                            ? setSelectedToken({
                                                address: t.address,
                                                symbol: t.symbol,
                                            })
                                            : null
                                    }
                                    className={`${isSelected ? "bg-[#73B0BE] text-black hover:opacity-90" : "hover:bg-[#FFFFFF22]"} ${t.currentPrice && "cursor-pointer"
                                        } ${(!t.currentValue && displayPricelessToken) ? "hidden" : ""} border-b pb-2`}
                                >
                                    <td>
                                        <TokenName token={t} isSelected={isSelected}></TokenName>
                                    </td>
                                    <td>
                                        {t.currentPrice ? (
                                            `$${t.currentPrice?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 4,
                                            })}`
                                        ) : (
                                            <p>N/A</p>
                                        )}
                                    </td>
                                    <td className="text-right py-4">
                                        <div>
                                            <p>
                                                {t.currentValue ? (
                                                    `$${t.currentValue?.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 4,
                                                    })}`
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </p>
                                            <p className={isSelected ? "text-black" : "text-[#ffffff7a]"}>
                                                {`${parseFloat(t.balance ?? '0').toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 8,
                                                })} ${t.symbol}`}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};


export default Holdings;
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { ComputedUserReserve, FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { Box, Flex, Heading, Input, InputGroup, InputRightAddon, Image, Text, Button } from "@chakra-ui/react";
import { ChangeEvent, useContext, useState } from "react";
import { ActionButton } from "../../global/buttons/ActionButton";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { ContractAddresses } from "../../../config";
import { useProvider, useSigner } from "wagmi";
import useTokenAllowance from "../../../hooks/useTokenAllowance";
import { ethers } from "ethers";
import { approveToken } from "../../../helpers/erc20Helpers";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import { supply } from "../../../services/aave/supply";
import { approveDelegation } from "../../../services/aave/approveDelegation";
import { borrow } from "../../../services/aave/borrow";
import { withdraw } from "../../../services/aave/withdraw";
import { AaveContext } from "./AaveProvider";
import { repay } from "../../../services/aave/repay";

interface AaveActionProps {
    action: string;
    userReserve: FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse> | undefined;
    reserve: ComputedUserReserve<ReserveDataHumanized & FormatReserveUSDResponse> | undefined;
    token: any;
    balance: number;
}

const aaveDebtTokenAbi = ['function borrowAllowance(address fromUser, address toUser) view returns (uint256)'];

const AaveAction: React.FC<AaveActionProps> = ({ action, userReserve, reserve, token, balance }) => {
    const { refresh } = useContext(AaveContext);
    const [amount, setAmount] = useState<number>(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { walletAddress } = useWalletContext();
    const provider = useProvider();
    const { data: signer } = useSigner();

    let allowanceToken = reserve?.underlyingAsset;
    let allowanceMethod = 'allowance';
    let allowanceAbi = undefined;
    if (action === 'borrow') {
        allowanceToken = reserve?.reserve?.variableDebtTokenAddress;
        allowanceMethod = 'borrowAllowance';
        allowanceAbi = aaveDebtTokenAbi;
    } else if (action === 'withdraw') {
        allowanceToken = reserve?.reserve?.aTokenAddress;
    }

    const { allowance, loading: allowanceLoading } = useTokenAllowance(
        walletAddress,
        ContractAddresses.ethereum.mainnet.BasicAave,
        allowanceToken,
        provider,
        amount,
        allowanceMethod,
        allowanceAbi,
        buttonLoading,
    );

    const handleUpdateInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setAmount(0.0);
            return;
        }
        setAmount(parseFloat(e.target.value ?? '0'));
    }

    const amountUSD = (amount ?? 0) * parseFloat(reserve?.reserve?.priceInUSD ?? '0')

    let infoDetailHeader = 'Supply APY';
    let infoDetail = `${(parseFloat(reserve?.reserve?.supplyAPY ?? '0') * 100).toFixed(2).toString()}%`;
    let newHF = (parseFloat(userReserve?.totalCollateralUSD ?? '0') + amountUSD) / parseFloat(userReserve?.totalBorrowsUSD ?? '0');
    let maxFn = () => setAmount(balance);
    let buttonTitle = `Supply ${reserve?.reserve?.symbol}`
    let buttonFn = async () => {
        if (!signer || !amount || !reserve?.underlyingAsset) return;

        setButtonLoading(true);
        try {
            await supply(
                signer,
                ethers.utils.parseUnits(amount.toString(), reserve?.reserve?.decimals),
                reserve?.underlyingAsset);
            refresh();
        } catch (e) {
            console.error(`Error with supply: ${e}`);
        } finally {
            setButtonLoading(false);
        }
    };
    let buttonDisabled = false;

    if (allowance && amount > parseFloat(ethers.utils.formatUnits(allowance, reserve?.reserve?.decimals))) {
        buttonTitle = `Approve ${reserve?.reserve?.symbol}`
        buttonFn = async () => {
            if (!reserve?.underlyingAsset || !reserve?.reserve || !signer) return

            setButtonLoading(true);
            try {
                await approveToken(
                    reserve?.underlyingAsset,
                    ContractAddresses.ethereum.mainnet.BasicAave,
                    ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals),
                    signer);
            } catch (e) {
                console.error(`Error approving token: ${e}`);
            } finally {
                setButtonLoading(false);
            }
        }
    }

    if (amount > balance) {
        buttonTitle = `Not Enough ${reserve?.reserve?.symbol}`
        buttonFn = async () => { }
        buttonDisabled = true;
    }


    switch (action) {
        case 'borrow': {
            infoDetailHeader = 'Variable APY';
            infoDetail = `${(parseFloat(reserve?.reserve?.variableBorrowAPY ?? '0') * 100).toFixed(2).toString()}%`;
            newHF = (parseFloat(userReserve?.totalCollateralUSD ?? '0')) / (parseFloat(userReserve?.totalBorrowsUSD ?? '0') + amountUSD);
            maxFn = () => setAmount(parseFloat(userReserve?.availableBorrowsUSD ?? '') / parseFloat(reserve?.reserve?.priceInUSD ?? ''));
            buttonTitle = `Borrow ${reserve?.reserve?.symbol}`
            buttonFn = async () => {
                if (!signer || !amount || !reserve?.underlyingAsset) return;

                setButtonLoading(true);
                // TODO - update to handle stable borrows (1 for interestRateMode instead of 2, 1 = stable, 2 = variable)
                try {
                    await borrow(
                        reserve?.reserve?.underlyingAsset,
                        ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals),
                        2,
                        signer);
                    refresh();
                } catch (e) {
                    console.error(`Error borrowing: ${e}`);
                } finally {
                    setButtonLoading(false);
                }
            }

            if (allowance && amount > parseFloat(ethers.utils.formatUnits(allowance, reserve?.reserve?.decimals))) {
                buttonTitle = `Approve ${reserve?.reserve?.symbol} Borrow`
                buttonFn = async () => {
                    if (!reserve?.underlyingAsset || !reserve?.reserve || !signer) return

                    setButtonLoading(true);
                    // TODO - update to handle stable borrows
                    try {
                        await approveDelegation(
                            reserve?.reserve?.variableDebtTokenAddress,
                            signer,
                            ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals)
                        );
                    } catch (e) {
                        console.error(`Error approving delegation: ${e}`);
                    } finally {
                        setButtonLoading(false);
                    }
                }
            }

            if (amountUSD > parseFloat(userReserve?.availableBorrowsUSD ?? '0')) {
                buttonTitle = `Not Enough Collateral`
                buttonFn = async () => { }
                buttonDisabled = true;
            }

            break;
        }
        case 'withdraw': {
            infoDetailHeader = 'Supply After'
            infoDetail = `${(parseFloat(reserve?.underlyingBalance ?? '0') - (amount ?? 0)).toFixed(4).toString()}`;
            newHF = (parseFloat(userReserve?.totalCollateralUSD ?? '0') - amountUSD) / parseFloat(userReserve?.totalBorrowsUSD ?? '0');
            maxFn = () => setAmount(parseFloat(reserve?.underlyingBalance ?? '0'));
            buttonTitle = `Withdraw ${reserve?.reserve?.symbol}`
            buttonFn = async () => {
                if (!signer || !amount || !reserve?.underlyingAsset) return;

                setButtonLoading(true);
                try {
                    await withdraw(
                        signer,
                        ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals),
                        reserve?.underlyingAsset);
                    refresh();
                } catch (e) {
                    console.log(`Error withdrawing: ${e}`);
                } finally {
                    setButtonLoading(false);
                }
                // TODO - update to handle stable borrows (1 for interestRateMode instead of 2, 1 = stable, 2 = variable)
            }
            if (allowance && amount > parseFloat(ethers.utils.formatUnits(allowance, reserve?.reserve?.decimals))) {
                buttonTitle = `Approve ${reserve?.reserve?.symbol} Withdrawal`
                buttonFn = async () => {
                    if (!reserve?.reserve?.aTokenAddress || !reserve?.reserve || !signer) return

                    setButtonLoading(true);
                    try {
                        await approveToken(
                            reserve?.reserve?.aTokenAddress,
                            ContractAddresses.ethereum.mainnet.BasicAave,
                            ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals),
                            signer);
                    } catch (e) {
                        console.log(`Error Approving token: ${e}`);
                    } finally {
                        setButtonLoading(false);
                    }
                }
            }

            if (amount > parseFloat(reserve?.underlyingBalance ?? '0')) {
                buttonTitle = `Not Enough Supplied`
                buttonFn = async () => { }
                buttonDisabled = true;
            }
            break;
        }
        case 'repay': {
            infoDetailHeader = 'Debt Remaining'
            infoDetail = (parseFloat(reserve?.totalBorrows ?? '0') - (amount ?? 0)).toFixed(4).toString();
            newHF = (parseFloat(userReserve?.totalCollateralUSD ?? '0')) / (parseFloat(userReserve?.totalBorrowsUSD ?? '0') - amountUSD);
            maxFn = () => setAmount(parseFloat(reserve?.totalBorrows ?? '0'));
            buttonTitle = `Repay ${reserve?.reserve?.symbol}`
            buttonFn = async () => {
                if (!signer || !amount || !reserve?.underlyingAsset) return;

                setButtonLoading(true);
                try {
                    await repay(
                        reserve?.reserve?.underlyingAsset,
                        ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals),
                        2,
                        signer);
                    refresh();
                } catch (e) {
                    console.log(`Error Repaying: ${e}`);
                } finally {
                    setButtonLoading(false);
                }
            }
            if (allowance && amount > parseFloat(ethers.utils.formatUnits(allowance, reserve?.reserve?.decimals))) {
                buttonTitle = `Approve ${reserve?.reserve?.symbol}`
                buttonFn = async () => {
                    if (!reserve?.reserve?.aTokenAddress || !reserve?.reserve || !signer) return

                    setButtonLoading(true);
                    try {
                        await approveToken(
                            reserve?.underlyingAsset,
                            ContractAddresses.ethereum.mainnet.BasicAave,
                            ethers.utils.parseUnits(`${amount}`, reserve?.reserve?.decimals),
                            signer);
                    } catch (e) {
                        console.error('Error approving token: ', e);
                    } finally {
                        setButtonLoading(false);
                    }
                }
            }

            if (amount > parseFloat(reserve?.totalBorrows ?? '0')) {
                buttonTitle = `Not Enough Borrowed`
                buttonFn = async () => { }
                buttonDisabled = true;
            }
            break;
        }
    }

    return (
        <div>
            <p className="text-3xl font-bold underline underline-offset-[12px]">{`${action[0].toUpperCase() + action.slice(1)}`}</p>
            <div className="flex gap-4 pt-4">
                <InputGroup>
                    <Input
                        type="number"
                        placeholder="0.0000"
                        onChange={handleUpdateInput}
                        value={amount}
                        onFocus={(e) => {
                            if (amount <= 0) {
                                setAmount(0)
                                e.target.value = ""
                            }
                        }}
                    />
                    <InputRightAddon gap={2}>
                        <img src={token?.image_thumb} />
                        <Text>{reserve?.reserve?.symbol}</Text>
                    </InputRightAddon>
                </InputGroup>
                <Button variant={'outline'} color={'mint.500'} onClick={maxFn}>
                    Max
                </Button>
            </div>
            <div className="pl-2 text-sm text-gray-400">${amountUSD.toFixed(4)}</div>
            <div className="flex py-4 font-primary">
                <div className="flex-1">
                    <h2 className="text-xl font-bold">{infoDetailHeader}</h2>
                    <p>{infoDetail}</p>
                </div>
                <div className="flex-1">
                    <h2 className="text-xl font-bold">Health Factor</h2>
                    <p>{parseFloat(userReserve?.healthFactor || "0").toFixed(4)} -&gt; {isNaN(newHF) ? '' : newHF.toFixed(2)}</p>
                </div>
            </div>
            <ActionButton style="primary" classes="w-full md:mt-10 font-bold text-2xl font-primary h-[3rem]" onClick={buttonFn}>{buttonLoading ? <SizzleAnimated size='xs' /> : buttonTitle}</ActionButton>
        </div>
    );
}

export default AaveAction;
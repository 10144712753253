const LidoRewards = () => {

  return (
    <section>
      <h2 className="text-3xl font-bold pt-10">Your Reward History</h2>
      <div className="md:flex text-center md:text-left items-center w-full justify-between">
        <span>
          <span className="text-xl font-light underline underline-offset-4">stETH Balance</span>
          <>
            <p className="text-lg font-light">$ 0</p>
            <p className="text-lg font-light">Ξ 0</p>
          </>
        </span>
        <span>
          <span className="text-xl font-light underline underline-offset-4">stETH Rewarded</span>
          <>
            <p className="text-lg font-light">$ 0</p>
            <p className="text-lg font-light">Ξ 0</p>
          </>
        </span>
        <span>
          <span className="text-xl font-light underline underline-offset-4">Average APR</span>
          <>
            <p className="text-lg font-light">-</p>
            <p className="text-lg font-light">&nbsp;</p>
          </>
        </span>
        <span>
          <span className="text-xl font-light underline underline-offset-4">stETH Price</span>
          <>
            <p className="text-lg font-light">$ 1,798.77</p>
            <p className="text-lg font-light">Ξ 0.99998391</p>
          </>
        </span>
      </div>
    </section>
  )
}

export default LidoRewards;
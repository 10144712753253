import React, { ReactNode, useState } from "react"

export const AaveContext = React.createContext({ refreshKey: 0, refresh: () => {}});

interface AaveProviderProps {
    children: ReactNode
}

export const AaveProvider: React.FC<AaveProviderProps> = ({ children }) => {
    const [refreshKey, setRefreshKey] = useState(0);

    const refresh = () => {
        setRefreshKey(refreshKey + 1);
    }

    return (
        <AaveContext.Provider value={{ refreshKey, refresh }}>
            {children}
        </AaveContext.Provider>
    )
}
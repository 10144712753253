import { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import erc20Abi from '@openzeppelin/contracts/build/contracts/ERC20.json'
import { ConfigVariables } from '../config';

const useUserTokenBalance = (address: string, userAccount?: string, refreshKey?: number) => {
  const [balance, setBalance] = useState<BigNumber | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      const fetchTokenBalance = async () => {
          if(!userAccount) return;

          const queryParams = new URLSearchParams({
              userAddress: userAccount || '',
          })
          setLoading(true);
          const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/token/${address}/balance?${queryParams}`, {
              method: 'GET', 
              headers: {
                  'Content-Type': 'application/json',
              },
          });
          const data = await response.json();
          setLoading(false);

          setBalance(data);
      }
    
      fetchTokenBalance();
  }, [address, userAccount, refreshKey]);

  return { balance, loading };
};

export default useUserTokenBalance;
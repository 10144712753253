import { Box, CircularProgress, Flex, Heading, Img, Table, Td, Tr, Text, IconButton, Center, Modal, ModalOverlay, ModalContent } from "@chakra-ui/react"
import { useWalletContext } from "../../../hooks/useWalletContext";
import useAllPositions from "../../../hooks/useAllPositions";
import UniswapPositionsHome from "./UniswapPositionsHome";
import useUniswapTopTokens from "../../../hooks/useUniswapTopTokens";
import { to } from "mathjs";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { formatLargeNumber } from "../../../helpers/general";
import { findPosition } from "../../../helpers/uniswapHelpers";
import { useContext, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useUniswapTopPools from "../../../hooks/useUniswapTopPools";
import { ActionButton } from "../../global/buttons/ActionButton";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import defaultTokenIcon from '../../../assets/icons/global/defaultTokenIcon.svg';
import { UniswapContext } from "./UniswapProvider";
import { UniswapToken } from "@BoolDigital/sizzle-types";

interface TokenSwap {
    token0?: string;
    token1?: string;
}
const UniswapMain = () => {
    const navigate = useNavigate();
    const { walletAddress, readOnly } = useWalletContext();
    const { refreshKey } = useContext(UniswapContext);
    const [tokenPage, setTokenPage] = useState(1);
    const [poolPage, setPoolPage] = useState(1);
    const { uniswapPositions, loadingUniswap } = useAllPositions(walletAddress, refreshKey);
    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
    const [selectedToken, setSelectedToken] = useState<any>(null);
    const { tokens, loading: loadingTopTokens } = useUniswapTopTokens();
    const { pools, loading: loadingTopPools } = useUniswapTopPools();

    const tokenPageDown = () => {
        if (tokenPage > 1) {
            setTokenPage(tokenPage - 1)
        }
    }

    const tokenPageUp = () => {
        if (tokenPage < 10) {
            setTokenPage(tokenPage + 1)
        }
    }

    const poolPageDown = () => {
        if (poolPage > 1) {
            setPoolPage(poolPage - 1)
        }
    }

    const poolPageUp = () => {
        if (poolPage < 10) {
            setPoolPage(poolPage + 1)
        }
    }

    const handleTrade = (token: any) => {
        let tokens: TokenSwap = {}

        if (token.token1) {
            tokens.token0 = token.token0.id
            tokens.token1 = token.token1.id
        } else {
            tokens.token0 = token.id
        }

        const tokensAsObject: Record<string, string> = { ...tokens };

        const urlParams = new URLSearchParams(tokensAsObject);
        navigate("/uniswap?" + urlParams)
        window.scrollTo({
            top: 270,
            behavior: 'smooth'
        })
    }

    const handleAddLiquidity = (p: any) => {
        navigate(`/uniswap/add-position?token0=${p.token0.id}&token1=${p.token1.id}`)
        window.scrollTo({
            top: 270,
            behavior: 'smooth'
        })
    }

    // const handleAccordion = (token: UniswapToken) => {
    //     setIsAccordionOpen(!isAccordionOpen)
    //     setSelectedToken(token)
    // }

    return (
        <div className="pb-4">
            <h1 className="text-3xl font-bold py-2">Uniswap</h1>
            {walletAddress ? <Outlet /> : <p className="border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary mx-auto text-center">Connect wallet to view assets or perform actions</p>}
            {
                loadingUniswap ? <SizzleAnimated size="md" /> : uniswapPositions && <UniswapPositionsHome positions={uniswapPositions} startOpen={true} hideHeader={true} />
            }
            <section className="pt-4">
                <h3 className="text-3xl font-bold">Top Tokens</h3>
                <table className="w-full">
                    <tr className="border-b-2 border-white">
                        <td className="text-left w-[3%] font-light">#</td>
                        <td className="text-left w-1/5 font-light">Token</td>
                        <td className="text-right w-1/12 font-light hidden md:table-cell">Price</td>
                        <td className="text-right w-1/12 font-light hidden md:table-cell">Volume 24H</td>
                        <td className="text-right w-1/12 font-light hidden md:table-cell">TVL</td>
                        <td className="text-right w-1/12 font-light"></td>
                    </tr>
                    {
                        loadingTopTokens ?
                            <tr className="w-full">
                                <td colSpan={7} className="py-8 text-center">
                                    <SizzleAnimated size="md" />
                                </td>
                            </tr>
                            :
                            tokens?.length && tokens?.slice((tokenPage - 1) * 10, tokenPage * 10)?.map((t: any, index: number) => {
                                return (
                                    <>
                                        <tr key={t.id} className="border-b pb-2">
                                            <td>{(index + 1) + (tokenPage - 1) * 10}</td>
                                            <td className="pt-2">
                                                <span className="flex gap-4">
                                                    <img src={t?.details?.image_small || defaultTokenIcon} alt={`${t.name} token icon`} />
                                                    <span>
                                                        <p>{t?.name}</p>
                                                        <p>{t?.symbol}</p>
                                                    </span>
                                                </span>
                                            </td>
                                            <td className="text-right hidden md:table-cell">{t?.priceUSD ? `$${parseFloat(t?.priceUSD).toFixed(2)}` : '-'}</td>
                                            <td className="text-right hidden md:table-cell">${formatLargeNumber(t?.volume24)}</td>
                                            <td className="text-right hidden md:table-cell">${formatLargeNumber(t?.totalValueLockedUSD)}</td>
                                            <td className="float-right mt-3 flex items-center"><ActionButton style={walletAddress ? "primary" : "primary-disabled"} onClick={() => handleTrade(t)} classes="font-bold h-[2rem] px-4" message="Sign In To Swap">Swap</ActionButton></td>
                                        </tr>
                                    </>
                                )
                            })
                    }
                    <tr>
                        <td colSpan={7}>
                            <div className="flex gap-4 items-center justify-center">
                                <ActionButton style="ghost" classes="hover:bg-gray-600 p-2 hover:rounded-md" aria-label="Page Down" onClick={tokenPageDown}><AiOutlineArrowLeft /></ActionButton>
                                <p>Page {tokenPage} of 10</p>
                                <ActionButton style="ghost" classes="hover:bg-gray-600 p-2 hover:rounded-md" aria-label="Page Down" onClick={tokenPageUp}><AiOutlineArrowRight /></ActionButton>
                            </div>
                        </td>
                    </tr>
                </table>
            </section>
            <section className="pt-4">
                <h3 className="text-3xl font-bold">Top Pools</h3>
                <table className="w-full">
                    <tr className="border-b-2 border-white">
                        <td className="w-[4%] font-light">#</td>
                        <td className="text-left w-1/5 font-light">Pool</td>
                        <td className="text-right w-1/4 font-light hidden md:table-cell">Volume 24H</td>
                        <td className="md:text-right text-center w-1/4 font-light">TVL</td>
                        <td className="font-light"></td>
                    </tr>
                    {
                        loadingTopPools ?
                            <tr className="w-full">
                                <td colSpan={7} className="py-8 text-center">
                                    <SizzleAnimated size="md" />
                                </td>
                            </tr>
                            :
                            pools?.length && pools?.slice((poolPage - 1) * 10, poolPage * 10)?.map((p: any, index: number) => {
                                return (
                                    <>
                                        <tr key={p.id} className="border-b pb-2">
                                            <td>{(index + 1) + (poolPage - 1) * 10}</td>
                                            <td className="pt-2">
                                                <span className="flex items-center gap-4">
                                                    <Flex>
                                                        <Box w={'32px'} h={'32px'}>
                                                            <Img src={p?.token0?.details?.image_small || defaultTokenIcon} />
                                                        </Box>
                                                        <Box w={'32px'} h={'32px'} ml={'-16px'}>
                                                            <Img src={p?.token1?.details?.image_small || defaultTokenIcon} />
                                                        </Box>
                                                    </Flex>
                                                    <Box>
                                                        <Text>{p?.token0?.symbol} / {p?.token1?.symbol}</Text>
                                                        <Text>{(p?.feeTier / 10000).toFixed(2)}%</Text>
                                                    </Box>
                                                </span>
                                            </td>
                                            <td className="text-right hidden md:table-cell">${formatLargeNumber(p?.volume24)}</td>
                                            <td className="md:text-right text-center">${formatLargeNumber(p?.totalValueLockedUSD)}</td>
                                            <td className="float-right h-full flex items-center justify-center">
                                                <span className="flex md:flex-row flex-col gap-4">
                                                    <ActionButton
                                                        onClick={() => handleAddLiquidity(p)}
                                                        style={walletAddress ? "primary" : "primary-disabled"}
                                                        classes="font-bold lg:h-[2rem] mt-3 py-1 px-4 whitespace-nowrap"
                                                    >
                                                        Create Position
                                                    </ActionButton>
                                                    <ActionButton
                                                        onClick={() => handleTrade(p)}
                                                        style={walletAddress ? "secondary" : "primary-disabled"}
                                                        classes="font-bold lg:h-[2rem] md:mt-3 py-1 p-4 whitespace-nowrap"
                                                    >
                                                        Trade
                                                    </ActionButton>
                                                </span>
                                            </td>


                                        </tr>
                                    </>
                                )
                            })
                    }
                    <tr>
                        <td colSpan={7}>
                            <div className="flex gap-4 items-center justify-center">
                                <ActionButton style="ghost" classes="hover:bg-gray-600 p-2 hover:rounded-md" aria-label="Page Down" onClick={poolPageDown}><AiOutlineArrowLeft /></ActionButton>
                                <p>Page {poolPage} of 10</p>
                                <ActionButton style="ghost" classes="hover:bg-gray-600 p-2 hover:rounded-md" aria-label="Page Down" onClick={poolPageUp}><AiOutlineArrowRight /></ActionButton>
                            </div>
                        </td>
                    </tr>
                </table>
            </section>
        </div>
    )
}

export default UniswapMain;
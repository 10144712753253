import React, { ReactNode, useState } from "react"

export const UniswapContext = React.createContext({ refreshKey: 0, refresh: () => {}});

interface UniswapProviderProps {
    children: ReactNode
}

export const UniswapProvider: React.FC<UniswapProviderProps> = ({ children }) => {
    const [refreshKey, setRefreshKey] = useState(0);

    const refresh = () => {
        setRefreshKey(refreshKey + 1);
    }

    return (
        <UniswapContext.Provider value={{ refreshKey, refresh }}>
            {children}
        </UniswapContext.Provider>
    )
}
import { useProvider } from "wagmi";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { ethers } from "ethers";
import { Flex, Image } from "@chakra-ui/react";
import useUserAccount from "../../../hooks/useUserAccount";
import CreateAccount from "../../CreateAccount";
import { Dispatch, SetStateAction, useState } from "react";
import AddWatchlistToken from "./AddWatchlistToken";
import { useUserWatchlist } from "../../../hooks/useUserWatchlist";
import { capitalizeWords, formatRateLabel, formatRateTooltip } from "../../../helpers/watchlistHelpers";
import { MdEdit } from "react-icons/md";
import EditWatchlistToken from "./EditWatchlistToken";
import { DashboardChartToken, WatchlistToken } from "@BoolDigital/sizzle-types";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import DefiRates from "./DefiRates";

interface WatchlistProps {
    selectedToken?: DashboardChartToken;
    setSelectedToken: (t: DashboardChartToken) => void;
    setWatchlist?: Dispatch<SetStateAction<any>>;
}

const Watchlist: React.FC<WatchlistProps> = ({ setSelectedToken, selectedToken }) => {
    const [isAccountCreated, setAccountCreated] = useState(false);
    const [reloadWatchlist, setReloadWatchlist] = useState(false);
    const { walletAddress, readOnly } = useWalletContext();
    const { user, isLoading } = useUserAccount(walletAddress, readOnly, isAccountCreated);
    const { watchlist, isLoading: watchlistLoading } = useUserWatchlist(user?.address, reloadWatchlist, isLoading);

    const reload = () => {
        setReloadWatchlist(!reloadWatchlist);
    }

    return (
        <div className="my-4">
            <div className="flex gap-4 items-center justify-between">
                <h2 className="text-3xl font-bold">Watchlist</h2>
                {
                    !user && !isLoading && <CreateAccount onAccountCreated={() => setAccountCreated(true)} />
                }
                {
                    user && !readOnly && <AddWatchlistToken user={user} watchlist={watchlist} onReloadWatchlist={reload} />
                }
            </div>
            {<table className="w-full">
                <tr className="border-b-2 border-white">
                    <td>Token</td>
                    <td>Price</td>
                    <td className="text-right">Protocols</td> {/* TODO: is numeric? */}
                </tr>
                {
                    isLoading || watchlistLoading ?
                        <tr>
                            <td colSpan={3}>
                                <div className="text-center py-2">
                                    <SizzleAnimated />
                                </div>
                            </td>
                        </tr>
                        : watchlist && watchlist.map((token: WatchlistToken | any) => {
                            let isSelected: boolean
                            isSelected = selectedToken?.address === "0x" ?
                                selectedToken?.symbol === token?.token?.symbol
                                :
                                selectedToken?.address === token?.token?.address
                            return (
                                <tr onClick={() => (token.currentPrice) ? setSelectedToken({ address: token?.token?.address, symbol: token?.token?.symbol }) : null} className={`${token.currentPrice && "cursor-pointer"} ${isSelected ? "bg-[#73B0BE] text-black hover:opacity-90" : "hover:bg-[#FFFFFF22]"} border-b pb-2`}>
                                    <td className="p-2">
                                        <Flex gap={2} alignItems={'center'}>
                                            <Image src={token?.token?.image_small} alt={token?.symbol} boxSize="32px" />
                                            {token?.token?.symbol.toUpperCase()}
                                        </Flex>
                                    </td>
                                    <td>${token.currentPrice?.toFixed(4)}</td>
                                    <td className="w-auto">
                                        <div className="flex gap-4 justify-end items-center flex-wrap">
                                            <DefiRates token={token} />
                                            {
                                                user && 
                                                <div onClick={(e) => e.stopPropagation()}>
                                                    <EditWatchlistToken user={user} onReloadWatchlist={reload} tokenToEdit={token} />
                                                </div>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                }
            </table>}
        </div>
    );
}

export default Watchlist;
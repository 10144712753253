import UniswapMain from "./UniswapMain"
import { UniswapProvider } from "./UniswapProvider"

const UniswapWrapper = () => {
    return (
        <UniswapProvider>
            <UniswapMain />
        </UniswapProvider>
    )
}

export default UniswapWrapper;
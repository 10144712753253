import { useState, useEffect } from 'react';
import { BigNumber, ethers } from 'ethers';

// ERC20 Token ABI
const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)"
];

type UseTokenBalanceProps = {
  provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider | undefined;
  walletAddress: string | undefined;
  tokenAddress: string | undefined;
  reload: boolean | undefined;
  isEth?: boolean;
};

export const useTokenBalance = ({ provider, walletAddress, tokenAddress, reload, isEth = false }: UseTokenBalanceProps) => {
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!provider || !walletAddress || (!isEth && !ethers.utils.isAddress(tokenAddress ?? ''))) return

    const fetchBalance = async () => {
      try {
        let balance;
        if(isEth) {
          balance = await provider.getBalance(walletAddress);
        }
        else if(tokenAddress) {
          const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
          balance = await contract.balanceOf(walletAddress);
        }
        setBalance(balance);
      } catch (err) {
        setError(err as Error);
      }
    };

    fetchBalance();
  }, [provider, walletAddress, tokenAddress, reload]);

  return { balance, error };
};
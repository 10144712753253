import React, { useEffect, useState } from "react";
import { ChakraProvider, Container, Heading, VStack, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";
import { WagmiConfig } from "wagmi";
import { ethereumClient, wagmiClient } from "./services/wagmiClient";
import { Web3Modal } from "@web3modal/react";
import { WalletContextProvider } from "./hooks/useWalletContext";
import AppGatewayModal from "./components/AppGatewayModal";
import MainLayout from "./components/MainLayout";
import UserPortfolioDashboard from "./components/Home/UserPortfolioDashboard";
import AaveMain from "./components/protocols/aave/AaveMain";
import AaveDetail from "./components/protocols/aave/AaveDetail";
import { ConfigVariables } from "./config";
import UniswapMain from "./components/protocols/uniswap/UniswapMain";
import SwapInterface from "./components/protocols/uniswap/SwapInterface";
import AaveWrapper from "./components/protocols/aave/AaveWrapper";
import { ManageInterface, AddLiquidity, RemoveLiquidity, Claim } from "./components/protocols/uniswap/manage";
import CreateInterface from "./components/protocols/uniswap/CreateInterface";
import LidoMain from "./components/protocols/lido/LidoMain";
import { LidoStake, LidoWrap, LidoWithdraw } from "./components/protocols/lido/actions";
import UniswapWrapper from "./components/protocols/uniswap/UniswapWrapper";

const theme = extendTheme({
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  colors: {
    mint: {
      50: '#e1f8fe',
      100: '#c4e3ea',
      200: '#a4cfd9',
      300: '#83bac7',
      400: '#62a7b6',
      500: '#498d9d',
      600: '#366e7b',
      700: '#234f58',
      800: '#0e3037',
      900: '#001316',
    }
  },
  styles: {
    global: (props: any) => ({
      body: {
        color: mode("#2D2D2D", "white")(props),
        bg: mode("gray.100", "#2D2D2D"),
      }
    }),
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  }
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    document.title = "Sizzle - DeFi, Simplified"
    if (localStorage.getItem('auth-token')) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('auth-token', 'true');
    setIsAuthenticated(true);
  };

  return (
    <ChakraProvider theme={theme}>
      <WalletContextProvider>
        <BrowserRouter>
          <Container w="100%" maxW="100%" p={0} m={0} className="font-primary">
            <WagmiConfig client={wagmiClient}>
              {
                <Routes>
                  <Route path="/" element={<MainLayout />}>
                    <Route index element={<UserPortfolioDashboard />} />
                    <Route path="/portfolio/historical/:tokenAddress/:range" element={<></>} />
                    <Route path="/aave" element={<AaveWrapper />}>
                      <Route path=":assetAddress" element={<AaveDetail />}>
                        <Route path=":action" element={<></>} />
                      </Route>
                    </Route>
                    <Route path="/uniswap" element={<UniswapWrapper />}>
                      <Route index element={<SwapInterface />} />
                      <Route path="manage/:positionId" element={<ManageInterface />}>
                        <Route path="add" element={<AddLiquidity />} />
                        <Route path="remove" element={<RemoveLiquidity />} />
                        <Route path="claim" element={<Claim />} />
                      </Route>
                      <Route path="add-position/:token0?/:token1?" element={<CreateInterface />} />
                    </Route>
                    <Route path="/lido" element={<LidoMain />}>
                      <Route index element={<LidoStake />} />
                      <Route path="wrap" element={<LidoWrap />} />
                      <Route path="withdraw" element={<LidoWithdraw />} />
                    </Route>
                  </Route>
                </Routes>
              }

            </WagmiConfig>
            <Web3Modal projectId={ConfigVariables.WALLETCONNECT_PROJECT_ID ?? ''} ethereumClient={ethereumClient} />
          </Container>
        </BrowserRouter>
      </WalletContextProvider>
    </ChakraProvider >
  );
}

export default App;
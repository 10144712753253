export const ContractAddresses = {
    ethereum: {
        mainnet: {
            UniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
            UniswapQuoter: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
            SwapUni: "0x5c2cbb87ad96885473cc5f0a61dc87f4d65b953b",
            LiquidityUni: "0x8360b6806b5DEBAEA3D8256BB7Eca78A6B9a5b25",
            BasicAave: "0x8B2cba6Fe065580462830bA47bE6b44979d2f472",
            WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            AavePool: "0x87870Bca3F3fD6335C3F4ce8392D69350B4fA4E2",
        },
        testFork: {
            UniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
            UniswapQuoter: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
            SwapUni: "0x5c2cbB87Ad96885473cC5f0a61DC87f4d65b953B",
            LiquidityUni: "0x8360b6806b5DEBAEA3D8256BB7Eca78A6B9a5b25",
            BasicAave: "0x8B2cba6Fe065580462830bA47bE6b44979d2f472",
            WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            AavePool: "0x87870Bca3F3fD6335C3F4ce8392D69350B4fA4E2",
        }
    }
}

export const ConfigVariables = {
    WALLETCONNECT_PROJECT_ID: 'b486d8d7252de28552ef023a3d362a7d',
    SERVER_URL: process.env.NODE_ENV === 'development' ? 'https://localhost:3000' : 'https://server.booldigital.com',
    GATEWAY_PASSWORD: 'bool2023',
    HTTPS: true,
}

import { useEffect, useState } from "react";
import { ConfigVariables } from "../config";
import { UniswapTopPool } from "@BoolDigital/sizzle-types";

const useUniswapTopPools = () => {
    const [pools, setPools] = useState<UniswapTopPool[]>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPools = async () => {
            setLoading(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/uniswap/top-pools/`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoading(false);
            setPools(data);
        }

        fetchPools();
    }, [])

    return { pools, loading };
}

export default useUniswapTopPools;
import { Input } from "@chakra-ui/react";
import { ActionButton } from "../../../global/buttons/ActionButton";
import CloseButton from "../../../global/buttons/CloseButton";
import { useState } from "react";
import { Request, Claim } from ".";

const LidoWithdraw = () => {
  const [action, setAction] = useState<string>("Request");

  const isRequest = action === "Request";
  return (
    <article className="relative border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary text-center">
      <CloseButton path="/lido" />
      <div className="md:flex mx-auto justify-between pt-4">
        <span className="flex gap-2">
          <ActionButton style={isRequest ? "primary" : "ghost-border"} onClick={() => setAction("Request")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Request</ActionButton>
          <ActionButton style={!isRequest ? "primary" : "ghost-border"} onClick={() => setAction("Claim")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Claim</ActionButton>
        </span>
        <span className="md:flex gap-6">
          <span className="md:text-left">
            <h2 className="text-lg tracking-wide underline underline-offset-4">Requests Ready</h2>
            <p className="text-lg font-light md:text-left">0</p>
          </span>
          <span className="md:text-left">
            <h2 className="text-lg tracking-wide underline underline-offset-4">Requests Pending</h2>
            <p className="text-lg font-light md:text-left">0</p>
          </span>
        </span>
      </div>
      {isRequest ?
        <Request />
        :
        <Claim />
      }
    </article>
  );
}

export default LidoWithdraw;
import { ActionButton } from "../../../../global/buttons/ActionButton";
import { useState } from "react";

const Claim = () => {
  const [checked, setChecked] = useState<string>("steth");

  return (
    <div>
      <div className="md:flex justify-between max-w-[50vw] mx-auto pt-4">
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">stETH Balance</span>
          <p className="text-lg font-light md:text-left">0.3532 stETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Withdrawal Mode</span>
          <p className="text-lg font-light md:text-left">Turbo</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Available To Claim</span>
          <p className="text-lg font-light md:text-left">1.242 ETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Pending Amount</span>
          <p className="text-lg font-light md:text-left">0.0 stETH</p>
        </span>
      </div>
      <div className="md:flex flex-col gap-2 py-4">
        <div className="p-2 flex items-center justify-between border border-[#A3A3A3] rounded-md w-1/2 gap-2 mx-auto">
          <span className="md:flex gap-2">
            <input type="checkbox" />
            <p>0.2040 ETH</p>
          </span>
          <p className="text-xs border-[0.5px] border-orange-500 rounded-md p-1">
            Pending
          </p>
        </div>
        <div className="p-2 flex items-center justify-between border border-[#A3A3A3] rounded-md w-1/2 gap-2 mx-auto">
          <span className="md:flex gap-2">
            <input type="checkbox" />
            <p>0.2040 ETH</p>
          </span>
          <p className="text-xs border-[0.5px] border-[#73B0BE] rounded-md p-1">
            Ready
          </p>
        </div>
      </div>
      <ActionButton style="primary" classes="font-semibold tracking-wide lg:h-[3rem] w-auto text-2xl py-1 px-4 whitespace-nowrap">Claim</ActionButton>
      <p className="py-2">Max Transaction Cost: $13.04</p>
    </div>
  );
}

export default Claim;
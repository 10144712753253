import { ethers } from "ethers";
import { Box, Text, Button, useDisclosure, Modal, ModalOverlay, ModalHeader, ModalBody, ModalContent, FormControl, FormLabel, Input, Flex, Alert, AlertIcon, ModalCloseButton, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useWalletContext } from "../hooks/useWalletContext";
import { createAccount } from "../services/accountServices";
import ConnectWalletModal from "./ConnectWalletModal";
import useUserAccount from "../hooks/useUserAccount";
import { trackEvent } from "../helpers/googleAnalyticsHelpers";

const CreateAccount = ({ onAccountCreated }: { onAccountCreated: () => void }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenConnect, onOpen: onOpenConnect, onClose: onCloseConnect } = useDisclosure();
    const { walletAddress, readOnly } = useWalletContext();
    const { user } = useUserAccount(walletAddress, readOnly, false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({ name: '', email: '' });
    const [connectedWalletError, setConnectedWalletError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const handleSubmit = async () => {
        if (connectedWalletError) {
            return;
        }

        let errorStatus = false;
        let newErrors = { name: '', email: '' };

        if (!name) {
            errorStatus = true;
            newErrors.name = 'Name is required';
        }

        if (!email || !validateEmail(email)) {
            errorStatus = true;
            newErrors.email = 'Valid email is required';
        }

        setErrors(newErrors);

        if (!errorStatus && walletAddress && !readOnly) {
            setIsLoading(true);
            // send to backend
            try {
                await createAccount({ address: walletAddress, name: name, email: email })
                onAccountCreated();
                trackEvent({
                    category: 'user',
                    action: 'create-account',
                    label: 'Create Account Button',
                    additionalParams: {
                        'wallet_address': walletAddress,
                        'email': email,
                        'success': true
                    }
                });
                onClose(); // Close modal on success
            } catch (error) {
                console.error(error); // Log error for debugging
                trackEvent({
                    category: 'user',
                    action: 'create-account',
                    label: 'Create Account Button',
                    additionalParams: {
                        'wallet_address': walletAddress,
                        'email': email,
                        'success': false,
                        'error': error
                    }
                });
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!walletAddress || readOnly) {
            setConnectedWalletError(true);
        } else {
            setConnectedWalletError(false);
        }
    }, [walletAddress, readOnly]);

    return (
        <Box>
            {walletAddress && <Button onClick={() => {
                onOpen();
                trackEvent({
                    category: 'user',
                    action: 'open-create-account-modal',
                    label: 'Create Account Button',
                    additionalParams: {
                        'wallet_address': walletAddress,
                    }
                });
            }} bgColor={'mint.400'}>Create Account</Button>}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create an Account</ModalHeader>
                    {!isLoading && <ModalCloseButton />}

                    {connectedWalletError && (
                        <Alert status="error" mt={3}>
                            <AlertIcon />
                            <Text as={'span'}>You must <Button
                                variant="link"
                                colorScheme="blue"
                                onClick={() => {
                                    onClose();
                                    setTimeout(() => onOpenConnect(), 100);
                                    trackEvent({
                                        category: 'connect_wallet',
                                        action: 'open_connect_modal',
                                        label: 'Create Account Connect Button',
                                        additionalParams: {
                                            'source': 'create-account-modal'
                                        }
                                    });
                                }}
                            >connect</Button> your wallet and cannot be using read-only mode
                            </Text>
                        </Alert>
                    )}

                    <ModalBody>
                        {isLoading ? (
                            <Flex justify="center">
                                <Spinner />
                            </Flex>
                        ) : (
                            <>
                                <FormControl id="name" isInvalid={!!errors.name && errors.name.length > 0}>
                                    <FormLabel>Name</FormLabel>
                                    <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    {errors.name && <Text color="red.500">{errors.name}</Text>}
                                </FormControl>
                                <FormControl id="email" mt={4} isInvalid={!!errors.email && errors.email.length > 0}>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {errors.email && <Text color="red.500">{errors.email}</Text>}
                                </FormControl>
                                <Button mt={4} mb={4} onClick={handleSubmit} bgColor={'mint.400'}>Create Account</Button>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ConnectWalletModal isOpen={isOpenConnect} onClose={onCloseConnect} />
        </Box>
    );
}

export default CreateAccount;
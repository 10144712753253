import { useParams } from "react-router";
import { useEffect, useState, useContext } from "react";
import useAaveUserReserves from "../../../hooks/useAaveUserReserve";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { ComputedUserReserve, FormatReserveUSDResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import useToken from "../../../hooks/useToken";
import { ethers } from "ethers";
import { formatLargeNumber } from "../../../helpers/general";
import useUserTokenBalance from "../../../hooks/useUserTokenBalance";
import AaveAction from "./AaveAction";
import { useNavigate } from "react-router-dom";
import { AaveContext } from "./AaveProvider";
import { ActionButton } from "../../global/buttons/ActionButton";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import CloseButton from "../../global/buttons/CloseButton";

const AaveDetail = () => {
    const { refreshKey } = useContext(AaveContext);
    const { walletAddress } = useWalletContext();
    const { assetAddress, action } = useParams();
    const navigate = useNavigate();
    const { aaveReserves, loading: reserveLoading } = useAaveUserReserves(walletAddress, refreshKey);
    const { balance, loading: balanceLoading } = useUserTokenBalance(assetAddress ?? '', walletAddress, refreshKey)
    const { token, loading: tokenLoading, err } = useToken(assetAddress);
    const [reserve, setReserve] = useState<ComputedUserReserve<ReserveDataHumanized & FormatReserveUSDResponse> | undefined>();

    useEffect(() => {
        const res = aaveReserves?.userReservesData?.find((r) => r.underlyingAsset === assetAddress)
        setReserve(res);
    }, [aaveReserves, assetAddress])

    const availableToBorrow = parseFloat(aaveReserves?.availableBorrowsUSD ?? '') / parseFloat(reserve?.reserve?.priceInUSD ?? '')

    return (
        <article className="relative flex flex-col border border-1 border-[#A3A3A3] rounded-2xl p-4">
            <CloseButton path="/aave" />
            {err ? (
                <p className="text-center text-red-400">Cannot load token information</p> // Display the error message
            )
                :
                (
                    <>

                        {reserveLoading ?
                            <div className="text-center">
                                <SizzleAnimated />
                            </div>
                            :
                            <>
                                <header className="md:flex md:justify-between">
                                    <div className="flex gap-4 items-center">
                                        <img src={token?.image_small} alt={token?.reserve?.name} className="bg-[#eee] rounded-full p-2" />
                                        <div>
                                            <p className="text-xl md:text-md">{reserve?.reserve?.name}</p>
                                            <p className="text-xl md:text-md text-gray-400">{reserve?.reserve?.symbol}</p>
                                        </div>
                                    </div>
                                    <div className="flex gap-4 font-primary py-4">
                                        <div>
                                            <p className="md:text-2xl">Reserve Size</p>
                                            <p className="md:text-2xl font-bold">${formatLargeNumber(parseFloat(reserve?.reserve?.totalLiquidityUSD!))}</p>
                                        </div>
                                        <div>
                                            <p className="md:text-2xl">Utilization</p>
                                            <p className="md:text-2xl font-bold">{parseFloat(reserve?.reserve?.borrowUsageRatio!).toFixed(4)}%</p>
                                        </div>
                                        <div>
                                            <p className="md:text-2xl">Price</p>
                                            <p className="md:text-2xl font-bold">${formatLargeNumber(parseFloat(reserve?.reserve?.priceInUSD!))}</p>
                                        </div>
                                    </div>
                                </header>
                                <div className="flex flex-col">
                                    <section className="md:flex order-2 md:order-1 pt-4 md:gap-8">
                                        <div className="flex-1">
                                            <div>
                                                <h2 className="text-3xl font-bold underline underline-offset-[12px]">Supply Info</h2>
                                                <div className="flex gap-8 pt-4 font-primary">
                                                    <span>
                                                        <p className="md:text-xl font-bold">Total Supplied</p>
                                                        <p className="font-medium">{formatLargeNumber(reserve?.reserve?.totalLiquidity)} of {formatLargeNumber(reserve?.reserve?.supplyCap)}</p>
                                                    </span>
                                                    <span>
                                                        <p className="md:text-xl font-bold">APY</p>
                                                        <p className="font-medium">{(parseFloat(reserve?.reserve?.supplyAPY ?? '0') * 100).toFixed(3)}%</p>
                                                    </span>
                                                    <span>
                                                        <p className="md:text-xl font-bold">Max LTV</p>
                                                        <p className="font-medium">{reserve?.reserve?.formattedBaseLTVasCollateral}%</p>
                                                    </span>
                                                </div>
                                                <div className="flex gap-8 pt-4 font-primary">
                                                    <span>
                                                        <p className="md:text-xl font-bold">Liquidation Threshold</p>
                                                        <p className="font-medium">{reserve?.reserve?.formattedReserveLiquidationThreshold}%</p>
                                                    </span>
                                                    <span>
                                                        <p className="md:text-xl font-bold">Liquidation Penalty</p>
                                                        <p className="font-medium">{reserve?.reserve?.formattedReserveLiquidationBonus}%</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1">
                                            <span>
                                                <h2 className="md:text-3xl font-bold underline underline-offset-[12px]">Borrow Info</h2>
                                                <div className="flex gap-4 pt-4 justify-between font-primary">
                                                    <span>
                                                        <p className="md:text-xl font-bold">Total Borrowed</p>
                                                        <p className="font-medium">{formatLargeNumber(reserve?.reserve?.totalDebt)} of {formatLargeNumber(reserve?.reserve?.borrowCap)}</p>
                                                    </span>
                                                    <span>
                                                        <p className="md:text-xl font-bold">Variable APY</p>
                                                        <p className="font-medium">{(parseFloat(reserve?.reserve?.variableBorrowAPY ?? '0') * 100).toFixed(3)}%</p>
                                                    </span>
                                                    <span>
                                                        <p className="md:text-xl font-bold">Stable APY</p>
                                                        <p className="font-medium">{reserve?.reserve?.stableBorrowRateEnabled ? `${(parseFloat(reserve?.reserve?.stableBorrowAPY ?? '0') * 100).toFixed(3)}%` : '-'}</p>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </section>
                                    <section className="flex flex-col md:flex-row order-1 md:order-2 pt-4 md:gap-8">
                                        <div className="flex-1 order-2 md:order-1 py-2">
                                            <div>
                                                <h2 className="text-3xl font-bold underline underline-offset-[12px]">Your Info</h2>
                                                <div className="flex justify-between py-4 font-primary">
                                                    <span>
                                                        <p className="md:text-xl font-bold">Available to Supply</p>
                                                        <p className="font-medium">{balanceLoading ? '' : parseFloat(ethers.utils.formatUnits(balance ?? 0, reserve?.reserve?.decimals)).toFixed(6)} {balanceLoading ? '' : reserve?.reserve?.symbol}</p>
                                                    </span>
                                                    <ActionButton style="primary" onClick={() => navigate('supply')} classes={`font-bold px-4 h-[2rem] ${action === "supply" ? "" : "bg-transparent border border-[#73B0BE]"}`}>Supply</ActionButton>
                                                </div>
                                                <div className="flex justify-between pb-2 font-primary">
                                                    <span>
                                                        <p className="md:text-xl font-bold">Available to Borrow</p>
                                                        <p className="font-medium">{availableToBorrow} {reserve?.reserve?.symbol}</p>
                                                    </span>
                                                    <ActionButton style="primary" onClick={() => navigate('borrow')} classes={`font-bold px-4 h-[2rem] ${action === "borrow" ? "" : "bg-transparent border border-[#73B0BE]"}`}>Borrow</ActionButton>
                                                </div>
                                                <div className="flex justify-between pb-2 font-primary">
                                                    <span>
                                                        <p className="md:text-xl font-bold">Amount Supplied</p>
                                                        <p className="font-medium">{reserve?.underlyingBalance} {reserve?.reserve?.symbol}</p>
                                                    </span>
                                                    <ActionButton style="secondary" onClick={() => navigate('withdraw')} classes={`font-bold ${action === "withdraw" ? "" : "bg-transparent border border-[#73B0BE] text-white"}`}>Withdraw</ActionButton>
                                                </div>
                                                <div className="flex justify-between pb-2">
                                                    <span>
                                                        <p className="md:text-xl font-bold font-primary">Amount Borrowed</p>
                                                        <p className="font-medium">{reserve?.totalBorrows} {reserve?.reserve?.symbol}</p>
                                                    </span>
                                                    <ActionButton style="secondary" onClick={() => navigate('repay')} classes={`font-bold ${action === "repay" ? "" : "bg-transparent border border-[#73B0BE] text-white"}`}>Repay</ActionButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 md:order-2 order-1">
                                            <AaveAction action={action ?? ''} userReserve={aaveReserves} reserve={reserve} token={token} balance={parseFloat(ethers.utils.formatUnits(balance ?? 0, reserve?.reserve?.decimals))} />
                                        </div>
                                    </section>
                                </div>
                            </>
                        }
                    </>
                )
            }
        </article>
    );
}

export default AaveDetail
import React, { useEffect, useState } from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import DashChart from './DashChart';
import Holdings from './Holdings';
import Positions from './Positions';
import { useWalletContext } from '../../hooks/useWalletContext';
import Watchlist from './watchlist/Watchlist';
import { useNavigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import { ChartRange, DashboardChartToken } from '@BoolDigital/sizzle-types';
import { ConfigVariables } from '../../config';
import { ActionButton } from '../global/buttons/ActionButton';
import { SizzleAnimated } from '../global/SizzleAnimated';
import { trackEvent } from '../../helpers/googleAnalyticsHelpers';

const UserPortfolioDashboard: React.FC = (props) => {
  const { walletAddress } = useWalletContext();
  const [chartRange, setChartRange] = useState<ChartRange>('1W');
  const [selectedToken, setSelectedToken] = useState<DashboardChartToken | undefined>();
  const [watchlist, setWatchlist] = useState<any>();
  const chartProps = { selectedToken, chartRange, setChartRange };
  const [displayPricelessToken, setDisplayPricelessToken] = useState<boolean>(true);

  const handleTokenSelection = (token: DashboardChartToken) => {
    if (token.address === selectedToken?.address) {
      if (!walletAddress) {
        setSelectedToken({
          address: "0x",
          symbol: "eth"
        })
      } else {
        setSelectedToken(undefined)
      }
      trackEvent({
        category: 'dash-chart',
        action: 'deselect-token',
        label: 'Deselect Selected Token',
        additionalParams: {
          'token_address': token.address,
        }
      });
    } else {
      setSelectedToken(token);
      trackEvent({
        category: 'dash-chart',
        action: 'select-token',
        label: 'Select Token',
        additionalParams: {
          'token_address': token.address,
        }
      });
    }
  }

  useEffect(() => {
    if (!walletAddress) {
      setSelectedToken({
        address: "0x",
        symbol: "eth"
      })
    } else {
      setSelectedToken(undefined)
    }
  }, [walletAddress])

  if (walletAddress) {
    return (
      <Box>
        <Box w={'100%'} h={'30vh'} mb={4}>
          <DashChart {...chartProps} displayPricelessToken={displayPricelessToken} />
        </Box>
        <Outlet />
        <Holdings
          setSelectedToken={handleTokenSelection}
          selectedToken={selectedToken}
          setDisplayPricelessToken={setDisplayPricelessToken}
          displayPricelessToken={displayPricelessToken}
        />
        <Positions />
        <Watchlist selectedToken={selectedToken} setSelectedToken={handleTokenSelection} setWatchlist={setWatchlist} />
      </Box>
    );
  } else {
    return (
      <Box w={'100%'} h={'30vh'} mb={4}>
        {/* @TODO - Connect this to connect wallet button modal <p className="border border-[#A3A3A3] rounded-2xl p-2 text-xl mb-4 font-font-primary mx-auto text-center
">Connect to Sizzle</p> */}
        <DashChart {...chartProps} displayPricelessToken={displayPricelessToken} />
        <Watchlist selectedToken={selectedToken} setSelectedToken={handleTokenSelection} setWatchlist={setWatchlist} />
      </Box>
    );
  }
};

export default UserPortfolioDashboard;
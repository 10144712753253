import { Box, Button, Center, Flex, Input, InputGroup, InputRightAddon, Text, useDisclosure, Image, Img, InputLeftElement, InputRightElement, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineSwap } from 'react-icons/ai'
import { TbArrowRight } from 'react-icons/tb'
import { UniswapPathStep, UniswapToken, UniswapTopToken } from "@BoolDigital/sizzle-types";
import SelectTokenModal from "./SelectTokenModal";
import useUniswapQuote from "../../../hooks/useUniswapQuote";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { Percent } from "@uniswap/sdk-core";
import { ActionButton } from "../../global/buttons/ActionButton";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import { approveToken, getTokenContractDetails } from "../../../helpers/erc20Helpers";
import { ConfigVariables, ContractAddresses } from "../../../config";
import useUniswapTopTokens from "../../../hooks/useUniswapTopTokens";
import defaultTokenIcon from '../../../assets/icons/global/defaultTokenIcon.svg';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import debounce from 'lodash/debounce'; // Import lodash debounce function
import { useDebouncedValue } from "../../../hooks/uniswap/useDebounceChangeHandler";
import { BigNumber, ethers } from "ethers";
import { useTokenBalance } from "../../../hooks/general/useTokenBalance";
import { useProvider, useSigner } from "wagmi";
import useTokenAllowance from "../../../hooks/useTokenAllowance";
import { swapExactIn } from "../../../services/uniswap/swap";

const SwapInterface = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { walletAddress, readOnly } = useWalletContext();
    const provider = useProvider();
    const { data: signer } = useSigner();
    const [token0, setToken0] = useState<UniswapToken | undefined>();
    const [token1, setToken1] = useState<UniswapToken | undefined>();
    const [amount0, setAmount0] = useState<string>('0.0');
    const [amount1, setAmount1] = useState<string>('0.0');
    const debouncedAmount0 = useDebouncedValue(amount0, 1000);
    const debouncedAmount1 = useDebouncedValue(amount1, 1000);
    const [type, setType] = useState<'in' | 'out'>('in');
    const [slippage, setSlippage] = useState<number>(0.10);
    const [txDeadline, setTxDeadline] = useState<number>(30);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const { balance } = useTokenBalance({ provider: provider as any, walletAddress: walletAddress, tokenAddress: token0?.address, reload: buttonLoading, isEth: (!token0?.address && token0?.symbol === 'eth') })
    const { allowance } = useTokenAllowance(
        walletAddress,
        ContractAddresses.ethereum.mainnet.SwapUni,
        token0?.address,
        provider,
        debouncedAmount0,
        undefined,
        undefined,
        buttonLoading
    );
    const { isOpen: token0Open, onOpen: onToken0Open, onClose: onToken0Close } = useDisclosure();
    const { isOpen: token1Open, onOpen: onToken1Open, onClose: onToken1Close } = useDisclosure();
    const [loading, setLoading] = useState<boolean>(false);


    const isEthIn = token0?.address === null && token0?.symbol?.toLowerCase() === 'eth';

    const { exactAmount, pricePer, loading: quoteLoading, path }: {
        exactAmount: string | undefined,
        pricePer: number | undefined,
        loading: boolean,
        path: UniswapPathStep[] | undefined
    } = useUniswapQuote(
        walletAddress,
        isEthIn ? ContractAddresses.ethereum.mainnet.WETH : token0?.address,
        token1?.address,
        type === 'in' ? debouncedAmount0 : debouncedAmount1,
        type
    );
    const { tokens, loading: loadingTopTokens }: { tokens: UniswapTopToken[] | undefined, loading: boolean } = useUniswapTopTokens();

    const fetchToken = async (tokenAddress: string) => {
        if (tokens?.length) {
            let tokenSelected: UniswapTopToken | undefined = tokens.find(
                (token) => token.id === tokenAddress
            );

            const { decimals }: { decimals: number } = await getTokenContractDetails(tokenAddress);

            if (tokenSelected) {
                let token: UniswapToken = {
                    address: tokenSelected.id,
                    decimals: decimals,
                    img_url: tokenSelected?.details?.image_small || defaultTokenIcon,
                    symbol: tokenSelected.symbol,
                };

                return token;
            }
        }
    };

    const handleSwapInputs = () => {
        setToken1(token0);
        setToken0(token1);
        setAmount1(amount0);
        setAmount0(amount1);
        if (!token1 && !token0) return;
        navigate(`/uniswap?token0=${token1?.address}&token1=${token0?.address}`)
    };

    const urlParams = new URLSearchParams(location.search);
    useEffect(() => {
        const token0FromURL: string | null = urlParams.get("token0");
        const token1FromURL: string | null = urlParams.get("token1");

        if (location.search === '') {
            setToken0(undefined);
            setToken1(undefined);
            return
        };

        if (!token1FromURL) setToken1(undefined)

        const fetchTokens = async () => {
            if (!loadingTopTokens && tokens) {
                if (token0FromURL) {
                    const token0: UniswapToken | undefined = await fetchToken(token0FromURL);
                    setToken0(token0);
                }
                if (token1FromURL) {
                    const token1: UniswapToken | undefined = await fetchToken(token1FromURL);
                    setToken1(token1);
                }
            }
        };

        fetchTokens();
    }, [location.search, tokens]);

    useEffect(() => {
        setLoading(true);
        if (type === 'in') {
            setAmount1(exactAmount ?? '0.0');
            setLoading(false);
        } else {
            setAmount0(exactAmount ?? '0.0');
            setLoading(false);
        }
    }, [exactAmount]);

    function getButtonState() {
        let label = "Select Tokens";
        let style: "primary-disabled" | "primary" | "primary-xs" | "secondary" | "ghost" | "ghost-xs" | "ghost-border" | "danger" = "primary-disabled";
        let buttonFn: () => Promise<void> = async () => { };

        if (token0 && token1) {
            if (balance?.lt(ethers.utils.parseUnits(amount0 === '' ? '0' : amount0, token0?.decimals))) {
                label = `Not Enough ${token0.symbol.toUpperCase()}`;
                style = "primary-disabled";
            } else if (allowance?.lt(ethers.utils.parseUnits(amount0 === '' ? '0' : amount0, token0?.decimals))) {
                label = `Approve ${token0.symbol}`;
                style = "primary";
                buttonFn = async () => {
                    if (!signer || !amount0 || !token0) return

                    try {
                        setButtonLoading(true);
                        await approveToken(
                            token0.address,
                            ContractAddresses.ethereum.mainnet.SwapUni,
                            ethers.utils.parseUnits(`${amount0}`, token0?.decimals),
                            signer
                        );
                    } catch (error) {
                        console.error("Transaction was rejected or failed", error);
                    } finally {
                        setButtonLoading(false);
                    }
                }
            } else {
                label = `Swap ${token0?.symbol?.toUpperCase()} to ${token1?.symbol?.toUpperCase()}`;
                style = "primary";
                buttonFn = async () => {
                    if (!signer || !token0 || !amount0 || !path) return

                    try {
                        setButtonLoading(true);
                        await swapExactIn(signer, ethers.utils.parseUnits(amount0 === '' ? '0' : amount0, token0?.decimals), path, isEthIn);
                    } catch (error) {
                        console.error("Transaction was rejected or failed", error);
                    } finally {
                        setButtonLoading(false);
                    }
                }
            }
        }

        return { label, style, buttonFn };
    }

    const { label: buttonLabel, style: buttonStyle, buttonFn } = getButtonState();

    return (
        <article className="border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary mx-auto">
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center md:justify-center md:pt-0 pt-4">
                <div className="lg:justify-end justify-start">
                    <InputGroup>
                        <Input
                            isDisabled={quoteLoading}
                            type="number"
                            defaultValue="0.0"
                            value={amount0}
                            onChange={(e) => {
                                setType('in');
                                if (e.target.value === "" || !isNaN(+e.target.value)) {
                                    setAmount0(e.target.value);
                                }
                            }}
                            onFocus={(e) => {
                                if (parseFloat(amount0) <= 0) {
                                    setAmount0("0.0")
                                    e.target.value = ""
                                }
                            }}
                            onBlur={(e) => {
                                e.target.value = e.target.value || '0.0';
                                setAmount0(e.target.value);
                            }}
                        />
                        <InputRightAddon onClick={onToken0Open} cursor={'pointer'}>
                            <div className="flex gap-2 items-center">
                                {token0 && <img src={token0?.img_url || defaultTokenIcon} className="h-[28px]" />}
                                <Text>{token0?.symbol?.toUpperCase() ?? 'SELECT'}</Text>
                                <AiOutlineCaretDown />
                            </div>
                            <SelectTokenModal isOpen={token0Open} onClose={onToken0Close} onTokenSelect={setToken0} tokenType="0" />
                        </InputRightAddon>
                    </InputGroup>
                </div>
                <div className="swap-svg rounded-md bg-[#73B0BE] lg:p-1 px-4 py-2 cursor-pointer md:mx-0 flex items-center w-auto">
                    <button
                        aria-label="Swap Input Order"
                        className="md:flex md:items-center text-center mx-auto"
                        onClick={handleSwapInputs} // Replace with your actual click handler function
                    >
                        <svg
                            stroke="#73B0BE"
                            fill="black"
                            strokeWidth="3px"
                            className="hover:fill-white hover:stroke-[#6E7777]"
                            viewBox="0 0 1024 1024"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8zM872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div>
                    <InputGroup>
                        <Input
                            isDisabled={quoteLoading}
                            type="number"
                            defaultValue="0.0"
                            value={amount1}
                            onChange={(e) => {
                                setType('out');
                                if (e.target.value === "" || !isNaN(+e.target.value)) {
                                    setAmount1(e.target.value);
                                }
                            }}
                            onFocus={(e) => {
                                if (parseFloat(amount1) <= 0) {
                                    setAmount1("0.0")
                                    e.target.value = ""
                                }
                            }}
                            onBlur={(e) => {
                                e.target.value = e.target.value || '0.0';
                                setAmount1(e.target.value);
                            }}
                        />
                        <InputRightAddon onClick={onToken1Open} cursor={'pointer'}>
                            <Flex alignItems={'center'} gap={2}>
                                {token1 && <img src={token1?.img_url || defaultTokenIcon} className="h-[28px]" />}
                                <Text>{token1?.symbol?.toUpperCase() ?? 'SELECT'}</Text>
                                <AiOutlineCaretDown />
                            </Flex>
                            <SelectTokenModal isOpen={token1Open} onClose={onToken1Close} onTokenSelect={setToken1} tokenType="1" />
                        </InputRightAddon>
                    </InputGroup>
                </div>
            </div>
            {
                loading || quoteLoading ?
                    <div className="p-8">
                        <SizzleAnimated size="sm" />
                    </div >
                    :
                    <div>
                        {(token0 && token1 && !quoteLoading) &&
                            (<div className="text-center mt-4 mb-2">
                                {pricePer && <p className="text-lg font-bold tracking-wide">1 {token0?.symbol?.toUpperCase()} = {pricePer?.toFixed(5)} {token1?.symbol?.toUpperCase()}</p>}
                            </div>)}
                        {
                            (token0 && amount0 !== undefined && parseFloat(amount0) !== 0) && (token1 && amount1 !== undefined && parseFloat(amount1) !== 0 && pricePer) && (
                                <div className="lg:flex gap-8 lg:justify-center lg:items-center mx-auto">
                                    <div className="font-bold flex flex-col items-center">
                                        <h3 className="underline underline-offset-[6px] text-lg">Max Slippage</h3>
                                        <div className="flex gap-2 items-center">
                                            <div className="relative">
                                                <input
                                                    className="p-1 font-gray-600 border border-[#A3A3A3] bg-inherit rounded-md md:w-[6vw]"
                                                    type="number"
                                                    value={slippage}
                                                    min={0}
                                                    onChange={(e) => {
                                                        const newValue = parseFloat(e.target.value);
                                                        const clampedValue = isNaN(newValue) ? newValue : Math.min(Math.max(newValue, 0), 100);
                                                        setSlippage(clampedValue);
                                                    }}
                                                />
                                                <span className="absolute hidden md:visible inset-y-0 right-2 md:flex items-center text-gray-300">
                                                    %
                                                </span>
                                            </div>
                                            <ActionButton onClick={() => setSlippage(0.1)} style="primary" classes="my-2 px-4">
                                                Auto
                                            </ActionButton>
                                        </div>
                                    </div>
                                    <div className="font-bold flex flex-col py-1 text-center">
                                        <h3 className="underline underline-offset-[6px] text-lg">Minimum Output</h3>
                                        <p className="py-2 tracking-wide">{slippage > 0 ? (parseFloat(amount1) * (1 - slippage / 100)).toFixed(4) : parseFloat(amount1).toFixed(4)}</p>
                                    </div>
                                    <div className="font-bold flex flex-col items-center">
                                        <h3 className="underline underline-offset-[6px] text-lg">Transaction Deadline</h3>
                                        <div className="flex gap-2 items-center">
                                            <input
                                                className="m-1 p-1 font-gray-600 border border-[#A3A3A3] bg-inherit rounded-md md:w-[6vw]"
                                                value={txDeadline}
                                                onChange={(e) => setTxDeadline(parseFloat(e.target.value))}
                                            />
                                            <p>min</p>
                                        </div>
                                    </div>
                                    <div className="font-bold flex flex-col items-center">
                                        <h3 className="underline underline-offset-[6px] py-1 text-lg">Route</h3>
                                        <div className="flex">
                                            {path &&
                                                path?.map((step, index) => {
                                                    return (
                                                        <div key={index} className="flex">
                                                            <img alt="uniswap step image" className="w-[28px]" src={step.img_url ?? defaultTokenIcon} />
                                                            {step.fee !== undefined && step.fee > 0 ? (
                                                                <span className="flex text-gray-600 font-light items-center">
                                                                    {/* <TbArrowRight fontSize={'0.8rem'} /> */}
                                                                    --
                                                                    <p className="font-md bg-gray-600 text-black px-2 rounded-md">
                                                                        {(step.fee / 10000).toFixed(2)}%
                                                                    </p>
                                                                    {/* <TbArrowRight fontSize={'0.8rem'} /> */}
                                                                    --
                                                                </span>
                                                            ) : undefined}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
            }
            <div className="text-center mt-2 font-bold">
                <ActionButton style={buttonStyle} classes="mx-auto h-[2rem] p-4 text-xl flex items-center text-center" onClick={buttonFn}>{buttonLoading ? <SizzleAnimated size="xs" /> : buttonLabel}</ActionButton>
            </div>
        </article >
    );
}

export default SwapInterface;
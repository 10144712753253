import { Input } from "@chakra-ui/react";
import { ActionButton } from "../../../global/buttons/ActionButton";
import CloseButton from "../../../global/buttons/CloseButton";
import { useState } from "react";

const LidoStake = () => {
  const [action, setAction] = useState<string>("Wrap");
  const [checked, setChecked] = useState<string>("steth");

  const isWrap = action === "Wrap";
  return (
    <article className="relative border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary text-center">
      <CloseButton path="/lido" />
      <div className="md:flex mx-auto justify-between pt-4">
        <span className="flex gap-2">
          <ActionButton style={isWrap ? "primary" : "ghost-border"} onClick={() => setAction("Wrap")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Wrap</ActionButton>
          <ActionButton style={!isWrap ? "primary" : "ghost-border"} onClick={() => setAction("Unwrap")} classes="font-bold lg:h-[2rem] py-1 px-4 whitespace-nowrap">Unwrap</ActionButton>
        </span>
        <span className="md:flex gap-6">
          <span className="md:text-left">
            <h2 className="text-lg tracking-wide underline underline-offset-4">Requests Ready</h2>
            <p className="text-lg font-light md:text-left">0</p>
          </span>
          <span className="md:text-left">
            <h2 className="text-lg tracking-wide underline underline-offset-4">Requests Pending</h2>
            <p className="text-lg font-light md:text-left">0</p>
          </span>
        </span>
      </div>
      <div className="md:flex justify-between max-w-[50vw] mx-auto pt-4">
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">ETH Balance</span>
          <p className="text-lg font-light md:text-left">0.0000 ETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">stETH Balance</span>
          <p className="text-lg font-light md:text-left">0.3532 stETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">wstETH Balance</span>
          <p className="text-lg font-light md:text-left">1.2434 wstETH</p>
        </span>
      </div>
      {isWrap && <div className="mx-auto py-4 flex justify-center gap-4">
        <div className="flex items-center gap-2 text-center">
          <input type="radio" className="radio radio-primary" checked={checked === "steth"} onClick={() => setChecked("steth")} />
          <label className="text-lg">stETH</label>
        </div>
        <div className="flex items-center gap-2 text-center">
          <input type="radio" className="radio radio-primary" checked={checked === "eth"} onClick={() => setChecked("eth")} />
          <label className="text-lg">ETH</label>
        </div>
      </div>}
      <div className="py-2 flex items-center w-1/3 gap-2 mx-auto">
        <Input
          type="number"
          defaultValue="0"
          className="bg-white"
        />
        <div>
          <ActionButton style="ghost" classes="p-1 border border-[#73B0BE] hover:bg-[#6E7777] hover:text-white rounded-md" onClick={() => console.log("max")}>
            Max
          </ActionButton>
        </div>
      </div>
      <p className="font-light py-2">You Receive: 0.0 wstETH</p>
      <ActionButton style="primary" classes="font-semibold tracking-wide lg:h-[3rem] w-auto text-2xl py-1 px-4 whitespace-nowrap">{action}</ActionButton>
      <div className={`md:flex ${isWrap ? "justify-between" : "justify-center gap-16"} max-w-[50vw] mx-auto pt-4`}>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Exchange Rate</span>
          <p className="text-lg font-light md:text-left">1 ETH = 0.875 ETH</p>
        </span>
        {isWrap && <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Max Approval Fee</span>
          <p className="text-lg font-light md:text-left">$4.49</p>
        </span>}
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Max Estimated Gas Cost</span>
          <p className="text-lg font-light md:text-left">$8.06</p>
        </span>
        {isWrap && <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Allowance</span>
          <p className="text-lg font-light md:text-left">0.0 wstETH</p>
        </span>}
      </div>
    </article>
  );
}

export default LidoStake;
import { UniswapPositionData } from '@BoolDigital/sizzle-types';
import { handlePosition } from "../../../../helpers/uniswapHelpers";
import { useOutletContext, useParams } from "react-router-dom";
import useAllPositions from "../../../../hooks/useAllPositions";
import { useWalletContext } from "../../../../hooks/useWalletContext";
import { useEffect, useState } from "react";
import { ActionButton } from '../../../global/buttons/ActionButton';
import { SizzleAnimated } from "../../../global/SizzleAnimated";

const RemoveLiquidity = () => {
  const { walletAddress } = useWalletContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(50);
  const [approved, setApproved] = useState<boolean>(false);
  const { position } = useOutletContext<{ position: UniswapPositionData }>();
  const processedPosition = handlePosition(position);
  const percentages: number[] = [25, 50, 75, 100]

  useEffect(() => {
    setApproved(false)
  }, [percentage])

  const handleAction = async (action: string) => {
    if (action === "approve") {
      // do something
      setLoading(true);
      console.log("...approving");
      setTimeout(() => {
        setApproved(true);
        setLoading(false);
      }, 3000)
    } else if (approved === true && action === "removing") {
      // do something
      console.log("...collecting");
    }
  };

  const formatToRemove = (amount: number) => {
    const result: number = amount * (percentage / 100);
    return result.toFixed(4);
  }

  return (
    <section className="text-center mx-auto mt-4 flex flex-col gap-4 justify-center items-center transition ease-in-out delay-150">
      {position ? (
        <>
          <h1 className="text-lg font-bold">Amount to Remove</h1>
          <p className="text-4xl font-bold">{percentage}%</p>
          <input type="range" min={0} max="100" onChange={(e: any) => setPercentage(parseInt(e.target.value))} value={percentage} className="accent-[#73B0BE]" />
          <div className="flex gap-2">
            {percentages.map((percent: number, index: number) => (
              <button
                key={index}
                aria-label={`Button to remove ${percent} percent`}
                className={`border border-[#73B0BE] ${percent === percentage && "bg-[#73B0BE] text-white"} text-[#73B0BE]  hover:bg-[#73B0BE] hover:text-white  rounded-md px-2`}
                onClick={() => setPercentage(percent)}
              >
                {percent !== 100 ? `${percent}%` : "All"}
              </button>
            ))}
          </div>
          {loading ? (
            <div className="flex justify-center items-center min-h-[12vh]">
              <SizzleAnimated />
            </div>
          ) : (
            <div className="flex gap-4">
              <section >
                <h1 className="text-xl text-left font-bold underline underline-offset-[6px]">Amount removing</h1>
                <div className="flex gap-6 justify-between items-center my-2">
                  <img src={processedPosition?.url0} className="w-[32px] h-[32px]" alt="Token 0" />
                  <p>{formatToRemove(parseFloat(processedPosition.amount0 ?? ''))} {processedPosition.token0Symbol}</p>
                </div>
                <div className="flex gap-2 justify-between items-center my-2">
                  <img src={processedPosition?.url1} className="w-[32px] h-[32px]" alt="Token 1" />
                  <p>{formatToRemove(parseFloat(processedPosition.amount1 ?? ''))} {processedPosition.token1Symbol}</p>
                </div>
              </section>
              <section>
                <h1 className="text-xl text-left font-bold underline underline-offset-[6px]">Collecting Fees</h1>
                <div className="flex gap-6 justify-between items-center my-2">
                  <img src={processedPosition?.url0} className="w-[32px] h-[32px]" alt="Token 0" />
                  <p>{parseFloat(processedPosition.uncollectedFees0 ?? '').toFixed(5)} {processedPosition.token0Symbol}</p>
                </div>
                <div className="flex gap-6 justify-between items-center my-2">
                  <img src={processedPosition?.url1} className="w-[32px] h-[32px]" alt="Token 1" />
                  <p>{parseFloat(processedPosition.uncollectedFees1 ?? '').toFixed(5)} {processedPosition.token1Symbol}</p>
                </div>
              </section>
            </div>
          )}
          {approved ? (
            <ActionButton style="primary" classes="px-8 font-bold h-[2em]" onClick={() => handleAction("remove")}>Remove Liquidity</ActionButton>
          ) : (
            <ActionButton style="primary" classes="px-8 font-bold h-[2em]" onClick={() => handleAction("approve")}>Approve</ActionButton>
          )}
        </>
      ) : (
        <div className="flex justify-center items-center">
          <SizzleAnimated size="sm" />
        </div>
      )}
    </section>
  );
};

export default RemoveLiquidity;

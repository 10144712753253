import { useEffect, useState } from "react";
import { FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { ConfigVariables } from "../config";

const useAllPositions = (currentAccount: string | undefined, refreshKey?: number) => {
    const [aaveUserSummary, setAaveUserSummary] = useState<FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse> | undefined>();
    const [uniswapPositions, setUniswapPositions] = useState<UniswapPositionData[]>();
    const [loadingAave, setLoadingAave] = useState(false);
    const [loadingUniswap, setLoadingUniswap] = useState(false);
  
    useEffect(() => {
        const fetchAaveSummary = async () => {
            if(!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingAave(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/aave-user-summary?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoadingAave(false);

            const userSummary = data?.aaveUserSummary;
            setAaveUserSummary(userSummary);
        }

        const fetchUniswapSummary = async () => {
            if(!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingUniswap(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/uniswap-user-summary?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoadingUniswap(false);
            const positions = data?.uniswapUserSummary;
            setUniswapPositions(positions);
        }
      
        fetchAaveSummary();
        fetchUniswapSummary();
    }, [currentAccount, refreshKey]);
  
    return { aaveUserSummary, loadingAave, uniswapPositions, loadingUniswap };
  };
  
  export default useAllPositions;
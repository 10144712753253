import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

interface ItemProps {
  setTrackIsActive: (active: boolean) => void;
  activeItem: number;
  constraint: number;
  itemWidth: number;
  positions: number[];
  gap: number;
  index: number;
  children: React.ReactNode;
}

export const Item: React.FC<ItemProps> = ({
  setTrackIsActive,
  activeItem,
  constraint,
  itemWidth,
  positions,
  gap,
  index,
  children
}) => {
  const [userDidTab, setUserDidTab] = useState(false);

  const handleFocus = () => setTrackIsActive(true);

  const handleBlur = () => {
    userDidTab && index + 1 === positions.length && setTrackIsActive(false);
    setUserDidTab(false);
  };

  const handleKeyUp = (event: React.KeyboardEvent) =>
    event.key === "Tab" &&
    !(activeItem === positions.length - constraint) &&
    setUserDidTab(true);

  const handleKeyDown = (event: React.KeyboardEvent) => event.key === "Tab" && setUserDidTab(true);

  return (
    <Flex
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      w={`${itemWidth}px`}
      _notLast={{
        mr: `${gap}px`
      }}
      h={'100%'}
      py="4px"
    >
      {children}
    </Flex>
  );
};
import { WatchlistToken } from "@BoolDigital/sizzle-types";
import { useWalletContext } from "../../../hooks/useWalletContext";
import useUserAccount from "../../../hooks/useUserAccount";
import { Modal, Tooltip, useDisclosure } from "@chakra-ui/react";

import { ArrowUpIcon } from "@chakra-ui/icons"
import { capitalizeWords, formatRateTooltip } from "../../../helpers/watchlistHelpers";
import { useWatchedTokenDefiRates } from "../../../hooks/useWatchedTokenDefiRates";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import DotLoader from "../../global/DotLoader";
import React, { useState } from "react";
import { RatesModal } from "../../../components/Home/watchlist/RatesModal";
import { trackEvent } from "../../../helpers/googleAnalyticsHelpers";

interface DefiRatesProps {
    token: WatchlistToken;
}

const DefiRates: React.FC<DefiRatesProps> = ({ token }) => {
    const { rates, isLoading: ratesLoading } = useWatchedTokenDefiRates(token);
    const { walletAddress } = useWalletContext();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showMore, setShowMore] = useState<boolean>(false);
    const visibleRates = rates?.slice(0, 7);

    if (ratesLoading) {
        return (
            <div><DotLoader /></div>
        );
    }

    if (!rates) {
        return (<div></div>);
    }

    const handleAllRates = (event: any) => {
        event.stopPropagation();
        setShowMore(!showMore);
        onOpen()
    }

    const handleClose = () => {
        onClose()
        setShowMore(!showMore)
    }

    const modalProps = {
        token,
        rates,
        handleClose
    }

    return (
        <div className="flex gap-4 justify-end items-center flex-wrap">
            {visibleRates?.map((rate: any, index: number) => (
                <Tooltip
                    key={index}
                    hasArrow
                    bgColor={'mint.700'}
                    color={'white'}
                    borderRadius={'15px'}
                    placement="top"
                    p={2}
                    label={
                        <div>
                            <div className="flex gap-4 items-center">
                                <img src={rate.protocolLogoUrl} alt={rate.protocol} className="w-10 h-10" />
                                <p>{capitalizeWords(rate.protocol)}</p>
                            </div>
                            {formatRateTooltip(rate)}
                        </div>
                    }
                >
                    <div className="flex gap-2 items-center">
                        <div
                            className="bg-black rounded-full p-1"
                            onClick={(event) => {
                                event.stopPropagation();
                                if (rate.protocol === 'aave') {
                                    window.open(`https://app.aave.com/reserve-overview/?underlyingAsset=${rate.protocolRateData.tokenAddress}&marketName=proto_mainnet_v3`, '_blank');
                                    trackEvent({
                                        category: 'watchlist',
                                        action: 'click-watched-defi-rate',
                                        label: 'Watched DeFi Rate',
                                        additionalParams: {
                                            'protocol': rate.protocol,
                                            'wallet_address': walletAddress,
                                            'watched_token_address': token.token.address,
                                        }
                                    });
                                    return;
                                }
                                if (rate.protocol === 'uniswap') {
                                    window.open(`https://app.uniswap.org/add/${rate.protocolRateData.token0Address}/${rate.protocolRateData.token1Address}/${rate.protocolRateData.feeTier}`, '_blank');
                                    trackEvent({
                                        category: 'watchlist',
                                        action: 'click-watched-defi-rate',
                                        label: 'Watched DeFi Rate',
                                        additionalParams: {
                                            'protocol': rate.protocol,
                                            'wallet_address': walletAddress,
                                            'watched_token_address': token.token.address,
                                            'token0_address': rate.protocolRateData.token0Address,
                                            'token1_address': rate.protocolRateData.token1Address,
                                            'fee_tier': rate.protocolRateData.feeTier
                                        }
                                    });
                                }
                            }}
                        >
                            <img src={rate.protocolLogoUrl} alt={rate.protocol} className="w-[20px] h-[20px]" />
                        </div>
                        <p className="text-medium">{rate.displayRate}%</p>
                    </div>
                </Tooltip>
            ))}

            {rates.length > 7 && (
                <>
                    <button
                        className="chevron-icon underline underline-offset-4"
                        onClick={(e) => handleAllRates(e)}
                    >
                        {!showMore &&
                            <p>+{rates.length - 7} more</p>
                        }
                    </button>

                    <Modal isOpen={isOpen} onClose={handleClose} size="md">
                        <RatesModal {...modalProps} />
                    </Modal>
                </>
            )}
        </div>
    );
}

export default DefiRates;
import { ethers, BigNumber } from 'ethers';
import aave from '../../contracts/contracts/BasicAave.sol/BasicAave.json';
import { ContractAddresses } from '../../config';

//     function swap(uint256 _amountIn, PathStep[] calldata _path) public returns (uint256 amountOut) {
export const withdraw = async (account: ethers.Signer, amount: BigNumber, token: string) => {
    const aaveContract = new ethers.Contract(ethers.utils.getAddress(ContractAddresses.ethereum.mainnet.BasicAave), aave.abi, account);

    const tx = await aaveContract.withdraw(token, amount);

    await tx.wait();
    console.log("Withdraw Complete");
}
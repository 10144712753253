import { useEffect, useState } from "react"
import { FeeAmount } from "@uniswap/v3-sdk";
import { ConfigVariables } from "../../config";
import { BigNumber } from "ethers";

const useUniswapPairData = (
    _tokenA: string | undefined, 
    _tokenB: string | undefined, 
    _tokenADecimals: number | undefined, 
    _tokenBDecimals: number | undefined, 
) => {
    const [poolData, setPoolData] = useState<Record<number, { price: number, sqrtPriceX96: BigNumber, liquidity: BigNumber, tick: number, tickSpacing: number, tokenOrder: string[] }> | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getPrices = async () => {
            if(!_tokenA || !_tokenB || !_tokenADecimals || !_tokenBDecimals) return;

            setLoading(true);
            const queryParams = new URLSearchParams({
                token0: _tokenA,
                token1: _tokenB,
                token0Decimals: _tokenADecimals.toString(),
                token1Decimals: _tokenBDecimals.toString()
            })
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/uniswap/token-pair-data?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            Object.keys(data).forEach(key => {
                const pool = data[key];
                if (pool.sqrtPriceX96) {
                    pool.sqrtPriceX96 = BigNumber.from(pool.sqrtPriceX96.hex);
                }
                if (pool.liquidity) {
                    pool.liquidity = BigNumber.from(pool.liquidity.hex);
                }
            });
            setPoolData(data);
            setLoading(false);
        };

        getPrices();
    }, [_tokenA, _tokenB, _tokenADecimals, _tokenBDecimals])

    return { poolData, loading };
}

export default useUniswapPairData;
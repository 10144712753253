import { Box, Flex, Text, Collapse, useDisclosure, Heading, Tooltip, Icon, Table, Tr, Td, Image, Button } from "@chakra-ui/react";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { RiQuestionLine } from "react-icons/ri";
import { FaInfinity } from "react-icons/fa";
import { ethers } from "ethers";
import { useEffect } from "react";
import { useNavigate } from 'react-router';
import { ActionButton } from "../../global/buttons/ActionButton";
import Add from "../../../assets/icons/uniswap/add.svg";
import { tickToPrice } from "../../../helpers/uniswapHelpers";
import DotLoader from "../../global/DotLoader";

interface UniswapPositionsHomeProps {
    positions?: UniswapPositionData[],
    startOpen?: boolean,
    hideHeader?: boolean,
    loading?: boolean,
}

const UniswapPositionsHome: React.FC<UniswapPositionsHomeProps> = (props) => {
    const { isOpen, onToggle, onOpen } = useDisclosure();
    const navigate = useNavigate();
    const totalBalance = props.positions?.reduce((accumulator, p) => {
        return accumulator
            + ((p.token0PriceUSD ?? 0) * (parseFloat(p.amount0 ?? '0')))
            + ((p.token1PriceUSD ?? 0) * (parseFloat(p.amount1 ?? '0')))
    }, 0);

    const totalUnclaimedFees = props.positions?.reduce((accumulator, p) => {
        return accumulator
            + ((p.token0PriceUSD ?? 0) * (parseFloat(p.uncollectedFees0 ?? '0')))
            + ((p.token1PriceUSD ?? 0) * (parseFloat(p.uncollectedFees1 ?? '0')))
    }, 0);

    useEffect(() => {
        if (props.startOpen) {
            onOpen();
        }
    }, []);

    const handlePositionsSelect = (position: UniswapPositionData) => {
        navigate(`/uniswap/manage/${position.id}`);
        window.scrollTo({
            top: 270,
            behavior: "smooth"
        });
    }

    const handleAddPosition = () => {
        navigate("/uniswap/add-position");
        window.scrollTo({
            top: 270,
            behavior: "smooth"
        });
    }

    return (
        <section className="font-primary">
            {!props.hideHeader && (
                <header className="flex gap-2 items-center py-2">
                    <h1 className="text-2xl py-4">Uniswap</h1>
                    <div className="border-2 border-white rounded-md cursor-pointer" onClick={onToggle}>
                        {isOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                    </div>
                </header>)}
            {props.loading ?
             <DotLoader /> :
             <>
                <div className="flex gap-8">
                    <section>
                        <div className="flex gap-1 items-center">
                            <h3 className="md:text-xl font-bold underline underline-offset-4 decoration-2 pb-2">Balance</h3>
                            <Tooltip hasArrow label="This is the combined value of all your liquidity positions in Uniswap V3 pools. 
                                It reflects the current market value of your positions in different pools." 
                                aria-label="Total Balance of Deposited Liquidity">
                                <span className="cursor-pointer">
                                    <Icon as={RiQuestionLine} boxSize={4} />
                                </span>
                            </Tooltip>
                        </div>
                        <h3 className="md:text-xl font-bold relative">
                            <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                            <span className="pl-2">
                                {totalBalance?.toFixed(2)}
                            </span>
                        </h3>
                    </section>
                    <section>
                        <div className="flex gap-1 items-center">
                            <h3 className="md:text-xl font-bold underline underline-offset-4 decoration-2 pb-2">Unclaimed Fees</h3>
                            <Tooltip hasArrow label="This shows the accumulated fees you can collect from your positions. 
                                It's an estimate of the earnings generated from providing liquidity that are available to collect." 
                                aria-label="Total Fees Available to Collect">
                                <span className="cursor-pointer">
                                    <Icon as={RiQuestionLine} boxSize={4} />
                                </span>
                            </Tooltip>
                        </div>
                        <h3 className="md:text-xl font-bold relative">
                            <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                            <span className="pl-2">
                                {totalUnclaimedFees?.toFixed(2)}
                            </span>
                        </h3>
                    </section>
                </div>
                <Collapse in={isOpen}>
                    <section className="pt-4">
                        <span className="flex gap-4 font-bold">
                            <p className="text-lg underline font-bold">Pools</p>
                            <ActionButton 
                                style="primary" 
                                classes="flex items-center px-1" 
                                onClick={() => handleAddPosition()}>
                                <img src={Add} alt="add new position icon" />
                                <p className="text-white p-1">Add New Position</p>
                            </ActionButton>
                        </span>
                        {props.positions?.length ? <table className="w-full">
                            <thead>
                                <tr className="border-b-2 border-white">
                                    <th className="text-left w-1/5 font-light">Pair</th>
                                    <th className="text-left w-1/5 font-light">Liquidity</th>
                                    <th className="text-left w-1/5 font-light hidden md:table-cell">Unclaimed Fees</th>
                                    <th className="text-left w-1/5 font-light hidden md:table-cell">Price Range</th>
                                    <th className="text-left w-1/5 font-light"></th>
                                </tr>
                            </thead>
                            {
                                props.positions?.map((p) => {
                                    const url0 = p.token0Img ?? `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(p.token0Address)}/logo.png?raw=true`
                                    const url1 = p.token1Img ?? `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(p.token1Address)}/logo.png?raw=true`

                                    const depositedUSD = (parseFloat(p.amount0 ?? '0') * (p.token0PriceUSD ?? 0)) + (parseFloat(p.amount1 ?? '0') * (p.token1PriceUSD ?? 0))
                                    const uncollectedFeesUSD = (parseFloat(p.uncollectedFees0 ?? '0') * (p.token0PriceUSD ?? 0)) + (parseFloat(p.uncollectedFees1 ?? '0') * (p.token1PriceUSD ?? 0))
                                    let minPrice;
                                    let maxPrice;
                                    if (p.tickLower <= -887270) {
                                        minPrice = 0;
                                    } else {
                                        minPrice = tickToPrice(p.tickLower, p.token0Decimals, p.token1Decimals);
                                    }

                                    if (p.tickUpper >= 887270) {
                                        maxPrice = null;
                                    } else {
                                        maxPrice = tickToPrice(p.tickUpper, p.token0Decimals, p.token1Decimals);
                                    }

                                    return (
                                        <tr key={p.id}> {/* @TODO add onclick handler */}
                                            <td className="pt-0">
                                                <Flex gap={2} align={'center'}>
                                                    <Flex>
                                                        <Box w={'32px'} h={'32px'}>
                                                            <Image src={url0} />
                                                        </Box>
                                                        <Box w={'32px'} h={'32px'} ml={'-16px'}>
                                                            <Image src={url1} />
                                                        </Box>
                                                    </Flex>
                                                    <span>
                                                        <p>{p.token0Symbol} / {p.token1Symbol}</p>
                                                        <p className="text-[#ffffff7a]">{p.feeTier / 10000}%</p>
                                                    </span>
                                                </Flex>
                                            </td>
                                            <td className="pt-2">
                                                <div>
                                                    <p>${depositedUSD.toFixed(2)}</p>
                                                    <p className="text-[#ffffff7a]">{parseFloat(p.amount0 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {p.token0Symbol}</p>
                                                    <p className="text-[#ffffff7a]">{parseFloat(p.amount1 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {p.token1Symbol}</p>
                                                </div>
                                            </td>
                                            <td className="hidden md:table-cell">
                                                <div>
                                                    <p>${uncollectedFeesUSD.toFixed(2)}</p>
                                                    <p className="text-[#ffffff7a]">{parseFloat(p.uncollectedFees0 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {p.token0Symbol}</p>
                                                    <p className="text-[#ffffff7a]">{parseFloat(p.uncollectedFees1 ?? '0').toFixed(5).replace(/\.?0+$/, "")} {p.token1Symbol}</p>
                                                </div>
                                            </td>
                                            <td className="hidden md:table-cell">
                                                <div>
                                                    <p>{p.token0Symbol} per {p.token1Symbol}</p>
                                                    <p className="text-[#ffffff7a]">Min: <span className="text-white">{minPrice.toFixed(5)}</span></p>
                                                    <p className="text-[#ffffff7a] flex gap-2 items-center">Max: <span className="text-white">{maxPrice ? maxPrice.toFixed(5) : <FaInfinity />}</span></p>
                                                </div>
                                            </td>
                                            <td className="float-right">
                                                <div className="flex gap-1 font-semibold my-3">
                                                    <ActionButton 
                                                        onClick={() => handlePositionsSelect(p)} 
                                                        style="primary" 
                                                        classes="h-[2rem] px-4" 
                                                        type="button">Manage</ActionButton>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </table> : <p className="md:text-xl mx-auto py-4">No positions added.</p>}
                    </section>
                </Collapse>
            </>}
        </section>
    );
}

export default UniswapPositionsHome;
import { Flex, Input, InputGroup, InputRightAddon, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UniswapToken } from "@BoolDigital/sizzle-types";
import useUniswapQuote from "../../../../hooks/useUniswapQuote";
import { useWalletContext } from "../../../../hooks/useWalletContext";
import { ActionButton } from "../../../global/buttons/ActionButton";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import { SizzleAnimated } from "../../../global/SizzleAnimated";
import CloseButton from "../../../global/buttons/CloseButton";
import debounce from 'lodash/debounce'; // Import lodash debounce function
import { string } from "mathjs";

const AddLiquidity = () => {
  const { position } = useOutletContext<{ position: any }>();
  const { walletAddress, readOnly } = useWalletContext();
  const [amount0, setAmount0] = useState<number>(0.0);
  const [amount1, setAmount1] = useState<number>(0.0);
  const [type, setType] = useState<'in' | 'out'>('in');
  const [txDeadline, setTxDeadline] = useState<number>(30);
  const [approvals, setApprovals] = useState<{ token0: boolean, token1: boolean }>({ token0: false, token1: false });
  const [loading, setLoading] = useState<boolean>(false);

  const { exactAmount, pricePer, loading: quoteLoading, path } = useUniswapQuote(walletAddress, position?.token0Address, position?.token1Address, type === 'in' ? amount0 : amount1, type);

  const debouncedOnChange =
    debounce((num: number, type: string) => {
      if (type === "in") {
        setAmount0(num)
      } else {
        setAmount1(num)
      }
    }, 500);

  useEffect(() => {
    if (type === 'in') {
      setAmount1(parseFloat(exactAmount ?? '0'))
    } else {
      setAmount0(parseFloat(exactAmount ?? '0'))
    }
  }, [exactAmount, amount0, amount1])

  const approveToken = (index: number) => {
    setLoading(true)
    if (index === 0) {
      setTimeout(() => {
        setApprovals({ ...approvals, token0: true });
        setLoading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setApprovals({ ...approvals, token1: true });
        setLoading(false);
      }, 3000);
    }
  }

  return (
    <article className="text-center mx-auto mt-4 flex flex-col gap-4 justify-center items-center transition ease-in-out delay-150">
      <CloseButton path="/uniswap" />
      {loading || !position ?
        <div>
          <SizzleAnimated size="sm" />
        </div>
        :
        <>
          <div className="lg:flex lg:gap-4 items-center lg:justify-center">
            <div className="py-2">
              <InputGroup>
                <Input
                  isDisabled={quoteLoading}
                  type="number"
                  defaultValue="0"
                  value={amount0}
                  onChange={(e) => {
                    setType('in');
                    debouncedOnChange(parseFloat(e.target.value ?? '0'), "in"); // Use debounced function
                  }}
                />
                <InputRightAddon>
                  <div className="flex gap-2 items-center">
                    {position?.url0 && <img src={position?.url0} alt={`${position?.token0Symbol} logo`} className="h-[28px]" />}
                    <Text>{position?.token0Symbol.toUpperCase()}</Text>
                  </div>
                </InputRightAddon>
              </InputGroup>
              <div className="flex gap-2 pt-2 items-center justify-center">
                <p className="text-lg">Balance: {position?.amount0}</p>
                <ActionButton style="primary-xs" classes="px-2 " onClick={() => setAmount0(position?.amount0)}>
                  Max
                </ActionButton>
              </div>
              <ActionButton
                style={`${approvals.token0 ? "primary-disabled" : "primary"}`}
                classes={`${approvals.token0 && "border rounded-md"} mt-4 px-8 font-bold h-[2em] font-bold`}
                onClick={() => approveToken(0)}
                isDisabled={approvals.token0}
              >
                {approvals.token0 ? "Approved" : `Approve`} {position?.token0Symbol}
              </ActionButton>
            </div>

            {/* Input Group for Amount 1 */}
            <div className="lg:justify-start">
              <InputGroup>
                <Input
                  isDisabled={quoteLoading}
                  type="number"
                  defaultValue="0"
                  value={amount1}
                  onChange={(e) => {
                    setType('out');
                    debouncedOnChange((parseFloat(e.target.value === '' ? '0' : e.target.value)), "out"); // Use debounced function
                  }}
                />
                <InputRightAddon>
                  <Flex alignItems={'center'} gap={2}>
                    {position?.url1 && <img src={position?.url1} alt={`${position?.token1Symbol} logo`} className="h-[28px]" />}
                    <Text>{position.token1Symbol?.toUpperCase()}</Text>
                  </Flex>
                </InputRightAddon>
              </InputGroup>
              <div className="flex gap-2 pt-2 items-center justify-center">
                <p className="text-lg">Balance: {position?.amount1}</p>
                <ActionButton style="primary-xs" classes="px-2" onClick={() => setAmount1(position?.amount1)}>
                  Max
                </ActionButton>
              </div>
              <ActionButton
                style={`${approvals.token1 ? "primary-disabled" : "primary"}`}
                classes={`${approvals.token1 && "border rounded-md"} mt-4 px-8 font-bold h-[2em] font-bold`}
                onClick={() => approveToken(1)}
                isDisabled={approvals.token1}
              >
                {approvals.token1 ? "Approved" : `Approve`} {position?.token1Symbol}
              </ActionButton>
            </div>
          </div>
          {/* {pricePer && <span className="font-bold text-lg"> */}
          {/* @TODO - CALCULATE  */}
          {/* 1 {position?.token0Symbol?.toUpperCase()} = {pricePer?.toFixed(5)} {position?.token1Symbol?.toUpperCase()} */}
          {/* </span>} */}

          <div className="text-center">
            {pricePer && (
              <p className="text-lg font-bold tracking-wide">
                1 {position?.token0Symbol?.toUpperCase()} = {pricePer?.toFixed(5)} {position?.token1Symbol?.toUpperCase()}
              </p>
            )}
          </div>

          <div className="text-center font-bold">
            <ActionButton
              style={approvals.token0 && approvals.token1 ? "primary" : "primary-disabled"}
              classes={`${!approvals.token0 && !approvals.token1 ? "border-2 rounded-md" : null} py-2 px-4 h-auto font-bold`}
              isDisabled={!approvals.token0 && !approvals.token1}
            >
              {approvals.token0 && approvals.token1 ?
                `Swap ${position?.token0Symbol} to ${position?.token1Symbol}`
                :
                "Approve Tokens"
              }
            </ActionButton>
          </div>
        </>
      }
    </article>
  );
}

export default AddLiquidity;

import { Input } from "@chakra-ui/react";
import { ActionButton } from "../../../global/buttons/ActionButton";
import CloseButton from "../../../global/buttons/CloseButton";

const LidoStake = () => {

  return (
    <article className="relative border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary text-center">
      <div className="md:flex mx-auto justify-center gap-14">
        <span>
          <h2 className="text-xl font-light underline underline-offset-4">Available To Stake</h2>
          <p className="text-lg font-light">1.249 ETH</p>
        </span>
        <span>
          <h2 className="text-xl font-light underline underline-offset-4">Staked Amount</h2>
          <p className="text-lg font-light">1.249 ETH</p>
        </span>
        <span>
          <h2 className="text-xl font-light underline underline-offset-4">Lido APR</h2>
          <p className="text-lg font-light">1.249 ETH</p>
        </span>
      </div>
      <div className="py-2 md:flex items-center w-1/3 gap-2 mx-auto">
        <Input
          type="number"
          defaultValue="0"

        />
        <div>
          <ActionButton style="ghost" classes="p-1 my-2 border border-[#73B0BE] hover:bg-[#6E7777] hover:text-white rounded-md" onClick={() => console.log("max")}>
            Max
          </ActionButton>
        </div>
      </div>
      <ActionButton style="primary" classes="font-semibold tracking-wide lg:h-[3rem] w-auto text-2xl py-1 px-4 whitespace-nowrap">Stake</ActionButton>
      <section className="pt-6">
        <div className="md:flex mx-auto justify-center gap-14">
          <span>
            <h2 className="text-xl font-light underline underline-offset-4">You Receive</h2>
            <p className="text-lg font-light">0.0000 ETH</p>
          </span>
          <span>
            <h2 className="text-xl font-light underline underline-offset-4">Exchange Rate</h2>
            <p className="text-lg font-light">1 ETH = 1 stETH</p>
          </span>
          <span>
            <h2 className="text-xl font-light underline underline-offset-4">Max Estimated Gas Cost</h2>
            <p className="text-lg font-light">$7.69</p>
          </span>
          <span>
            <h2 className="text-xl font-light underline underline-offset-4">Reward Fee</h2>
            <p className="text-lg font-light">10%</p>
          </span>
        </div>
      </section>
    </article>
  );
}

export default LidoStake;
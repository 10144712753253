import { ethers, BigNumber } from "ethers";
import { ContractAddresses } from "../../config";

const ercAbi = [
    "function approveDelegation(address delegatee, uint256 amount)"
  ];

export const approveDelegation = async (debtAddress: string, owner: ethers.Signer, amount: BigNumber, ) => {
    const tx = await (new ethers.Contract(debtAddress, ercAbi, owner)).approveDelegation(ContractAddresses.ethereum.mainnet.BasicAave, amount);
    await tx.wait();
}
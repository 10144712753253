import { Input } from "@chakra-ui/react";
import { ActionButton } from "../../../../global/buttons/ActionButton";
import CloseButton from "../../../../global/buttons/CloseButton";
import { useState } from "react";

const Request = () => {
  const [checked, setChecked] = useState<string>("steth");

  return (
    <div>
      <div className="md:flex justify-between max-w-[50vw] mx-auto pt-4">
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">stETH Balance</span>
          <p className="text-lg font-light md:text-left">0.3532 stETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Withdrawal Mode</span>
          <p className="text-lg font-light md:text-left">Turbo</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Available To Claim</span>
          <p className="text-lg font-light md:text-left">1.242 ETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Pending Amount</span>
          <p className="text-lg font-light md:text-left">0.0 stETH</p>
        </span>
      </div>
      <div className="mx-auto py-4 flex justify-center gap-4">
        <div className="md:flex items-center gap-2 text-center">
          <input type="radio" className="radio radio-primary" checked={checked === "steth"} onClick={() => setChecked("steth")} />
          <label className="text-lg">stETH</label>
        </div>
        <div className="md:flex items-center gap-2 text-center">
          <input type="radio" className="radio radio-primary" checked={checked === "eth"} onClick={() => setChecked("eth")} />
          <label className="text-lg">ETH</label>
        </div>
      </div>
      <div className="py-2 md:flex items-center w-1/3 gap-2 mx-auto">
        <Input
          type="number"
          defaultValue="0"
          className="bg-white"
        />
        <div>
          <ActionButton style="ghost" classes="p-1 my-2 border border-[#73B0BE] hover:bg-[#6E7777] hover:text-white rounded-md" onClick={() => console.log("max")}>
            Max
          </ActionButton>
        </div>
      </div>
      <ActionButton style="primary" classes="font-semibold tracking-wide lg:h-[3rem] w-auto text-2xl py-1 px-4 whitespace-nowrap">Request</ActionButton>
      <div className={`md:flex justify-between max-w-[50vw] mx-auto pt-4`}>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Exchange Rate</span>
          <p className="text-lg font-light md:text-left">1 ETH = 0.875 ETH</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Max Approval Fee</span>
          <p className="text-lg font-light md:text-left">$4.49</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Max Estimated Gas Cost</span>
          <p className="text-lg font-light md:text-left">$8.06</p>
        </span>
        <span>
          <span className="text-xl tracking-wide underline underline-offset-4">Allowance</span>
          <p className="text-lg font-light md:text-left">0.0 wstETH</p>
        </span>
      </div>
    </div>
  );
}

export default Request;
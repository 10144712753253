import { useEffect, useState } from "react";
import { ConfigVariables } from "../config";
import { UniswapTopToken } from "@BoolDigital/sizzle-types";

const useUniswapTopTokens = () => {
    const [tokens, setTokens] = useState<UniswapTopToken[]>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTokens = async () => {
            setLoading(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/uniswap/top-tokens/`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoading(false);
            setTokens(data);
        }

        fetchTokens();
    }, [])

    return { tokens, loading };
}

export default useUniswapTopTokens;
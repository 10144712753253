import { Outlet, useNavigate, useLocation } from "react-router";
import AavePositionsHome from "./AavePositionsHome";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import { useProvider } from "wagmi";
import useAllPositions from "../../../hooks/useAllPositions";
import { ethers } from "ethers";
import { FaCheckCircle, FaCircleNotch } from "react-icons/fa";
import useAllAaveReserves from "../../../hooks/useAllAaveReserves";
import React, { useContext, useState } from "react";
import { Switch } from "../../global/Switch";
import { ActionButton } from "../../global/buttons/ActionButton";
import { ComputedUserReserve, FormatReserveUSDResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers/dist/esm/v3-UiPoolDataProvider-contract";
import { AaveContext } from "./AaveProvider";

const AaveMain = () => {
    const { walletAddress, readOnly } = useWalletContext();
    const { refreshKey } = useContext(AaveContext);
    const { aaveUserSummary, loadingAave } = useAllPositions(walletAddress, refreshKey);
    const { aaveReserves, loading } = useAllAaveReserves(walletAddress, refreshKey);
    const [showZeroBalance, setShowZeroBalance] = useState(false);
    const navigate = useNavigate();

    const showAsset = async (token: ComputedUserReserve<ReserveDataHumanized & FormatReserveUSDResponse>, action?: string) => {
        window.scrollTo({
            top: 280,
            behavior: "smooth", // Adds a smooth scrolling animation
        });

        navigate(`/aave/${token.underlyingAsset}/${action ?? 'supply'}`);
    }

    return (
        <div className="pb-4">
            <h1 className="text-3xl font-bold py-2">Aave</h1>
            {!walletAddress && <p className="border border-[#A3A3A3] rounded-2xl p-2 my-4 font-font-primary mx-auto text-center">Connect wallet to view assets or perform actions</p>}
            {
                loadingAave ? <SizzleAnimated /> : aaveUserSummary && <AavePositionsHome summary={aaveUserSummary} startOpen={true} hideHeader={true} />
            }

            <p className="text-3xl font-bold pt-4">All Available Assets</p>
            <div className="flex gap-6 pt-4">
                <span className="flex gap-2 items-center py-2">
                    <p>Show assets with 0 balance:</p>
                    <Switch changeEvent={(e) => {
                        setShowZeroBalance(e.target.checked)
                    }} />
                </span>
                <span className="flex gap-2 items-center">
                    <FaCheckCircle color={'green'} size={'20px'} />
                    <p>Can be collateral</p>
                </span>
            </div>
            <table className="w-full pt-4">
                <tr className="border-b-2 border-white">
                    <th className="text-left w-1/5 font-light">Token</th>
                    <th className="text-left w-1/5 font-light hidden md:table-cell">Balance</th>
                    <th className="text-left w-1/5 font-light hidden md:table-cell">Supply APY</th>
                    <th className="text-left w-1/5 font-light hidden md:table-cell">Variable Borrow APY</th>
                    <th className="text-left w-1/5 font-light hidden md:table-cell">Stable Borrow APY</th>
                    <th className="text-left w-1/5 font-light"></th>
                </tr>
                {
                    loading ?
                        <tr>
                            <td className="text-center py-4" colSpan={6}><SizzleAnimated /></td>
                        </tr>
                        :
                        aaveReserves?.length && aaveReserves?.filter((r) => showZeroBalance ? true : r?.balance && parseFloat(r?.balance) > 0)?.sort((a, b) => {
                            const aValue = a.balance ? parseFloat(a.balance) : 0;
                            const bValue = b.balance ? parseFloat(b.balance) : 0;

                            if (aValue > bValue) return -1;
                            if (aValue < bValue) return 1;

                            return (b.availableLiquidity ?? 0) - (a.availableLiquidity ?? 0);
                        })?.map((reserve) => {
                            const supplyAPY = parseFloat(reserve?.supplyAPY ?? '0') * 100;
                            const variableBorrowAPY = parseFloat(reserve?.variableBorrowAPY ?? '0') * 100;
                            const stableBorrowAPY = parseFloat(reserve?.stableBorrowAPY ?? '0') * 100;
                            return (
                                <tr onClick={() => showAsset(reserve)} className="w-full cursor-pointer border-b pb-2">
                                    <td>
                                        <div className="flex gap-4 items-center">
                                            <img src={reserve?.token?.image_small} className="w-[32px] h-[32px] rounded-full" />
                                            <div>
                                                <span className="flex gap-2 items-center">
                                                    <p>{reserve?.name.length > 12 ? `${reserve?.name.slice(0, 12)}...` : reserve?.name}</p>
                                                    {
                                                        reserve?.usageAsCollateralEnabled && <FaCheckCircle color={'green'} size={'16px'} />
                                                    }
                                                </span>
                                                <p className="text-gray-500">{reserve?.symbol}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="hidden md:table-cell">
                                        {reserve?.balance && parseFloat(reserve?.balance) > 0 && 
                                            <span>
                                                <p className="text-gray-400">{parseFloat(reserve?.balance)?.toFixed(4)} {reserve?.symbol}</p>
                                            </span>
                                        }
                                    </td>
                                    <td className="hidden md:table-cell">
                                        <p>{supplyAPY.toFixed(2)}%</p>
                                    </td>
                                    <td className="hidden md:table-cell">
                                        <p>{variableBorrowAPY.toFixed(2)}%</p>
                                    </td>
                                    <td className="hidden md:table-cell">
                                        <p>{reserve.stableBorrowRateEnabled ? stableBorrowAPY.toFixed(2) : ' - '}%</p>
                                    </td>
                                    <td>
                                        <div className="flex md:flex-row flex-col md:py-0 py-2 gap-1 float-right">
                                            <ActionButton style="primary" classes="px-4 h-[2rem] font-bold" onClick={() => {
                                                showAsset(reserve, 'supply');
                                            }}>Supply</ActionButton>
                                            <ActionButton style="secondary" classes="font-bold" onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation()
                                                showAsset(reserve, 'borrow');
                                            }}>Borrow</ActionButton>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                }
            </table>
        </div>
    )
}

export default AaveMain